import React, { useState } from "react";
import PropTypes from "prop-types";

import Card from "@mui/material/Card";
import CardContent from "@mui/material/CardContent";
import Grid from "@mui/material/Grid";
import TextField from "@mui/material/TextField";

import RHFMultiSelect from "./RHFMultiSelect";

import PatientSelects from "./PatientSelects";
import moment from "moment";
import { useSelector } from "react-redux";
import { useEffect } from "react";

export default function PatientInformation(props) {
  const { states, counties } = useSelector((state) => state.caseDropdown.caseDropdown);
  const [filteredCounties, setFilteredCounties] = useState([]);
  const [patientReadOnly, setPatientReadOnly] = useState(false);
  const [stateChanged, setStateChanged] = useState(false);
  const patient = useSelector((state) => state.patientSearch);

  useEffect(() => {
    if (props.caseId || patient.patientSelected) {
      setPatientReadOnly(true);
    } else {
      setPatientReadOnly(false);
    }
  }, [props, patient]);

  useEffect(() => {
    if (!props.caseId && !stateChanged) {
      handleStateChange(
        null,
        states?.find((s) => s.code === process.env.DEFAULT_STATE)
      );
    }
  }, [props]);

  const selectProps = {
    ...props,
    patientReadOnly,
  };

  const handleStateChange = (e, v) => {
    if (v?.id) {
      setStateChanged(true);
    }
    props.setValue("patient.address.state_id", v);
    const c = counties.filter((county) => county.state_id === v.id);
    setFilteredCounties(c);
  };

  return (
    <Card className="mt-3" variant="outlined">
      <CardContent>
        <p className="m-0 card-header">PATIENT INFORMATION</p>
        <Grid className="pt-3" container spacing={2}>
          <Grid item xs={3}>
            <TextField
              className="w-100"
              name="patient.last_name"
              inputProps={{ "data-testid": "patient.last_name", readOnly: patientReadOnly }}
              label="Last Name *"
              variant="standard"
              InputLabelProps={{ shrink: props.watch("patient.last_name") !== "" }}
              error={!!props.errors.patient?.last_name}
              helperText={props.errors.patient?.last_name && props.errors.patient?.last_name.message}
              {...props.register("patient.last_name")}
            />
          </Grid>
          <Grid item xs={3}>
            <TextField
              className="w-100"
              name="patient.first_name"
              inputProps={{ "data-testid": "patient.first_name", readOnly: patientReadOnly }}
              label="First Name *"
              variant="standard"
              InputLabelProps={{ shrink: props.watch("patient.first_name") !== "" }}
              error={!!props.errors.patient?.first_name}
              helperText={props.errors.patient?.first_name && props.errors.patient?.first_name.message}
              {...props.register("patient.first_name")}
            />
          </Grid>
          <Grid item xs={3}>
            <TextField
              className="w-100"
              variant="standard"
              name="patient.middle_name"
              inputProps={{ "data-testid": "patient.middle_name", readOnly: patientReadOnly }}
              InputLabelProps={{ shrink: props.watch("patient.middle_name") !== "" }}
              label="Middle Name"
              {...props.register("patient.middle_name")}
            />
          </Grid>
          <Grid item xs={3}>
            <TextField
              className="w-100"
              variant="standard"
              name="patient.dob"
              type="date"
              pattern="\d{4}-\d{2}-\d{2}"
              InputLabelProps={{ shrink: true }}
              inputProps={{ "data-testid": "patient.dob", readOnly: patientReadOnly, max: "9999-12-31" }}
              label="Date of Birth *"
              error={!!props.errors.patient?.dob}
              helperText={`(if unavailable please enter 01/01/${moment().subtract(99, "years").year()})`}
              {...props.register("patient.dob")}
            />
          </Grid>
        </Grid>

        <Grid className="pt-3" container spacing={2}>
          <Grid item xs={3}>
            <TextField
              className="w-100"
              variant="standard"
              name="patient.address.street"
              inputProps={{ "data-testid": "patient.address.street", readOnly: patientReadOnly }}
              InputLabelProps={{ shrink: props.watch("patient.address.street") !== "" }}
              error={!!props.errors.patient?.address?.street}
              helperText={props.errors.patient?.address?.street && props.errors.patient?.address?.street.message}
              label="Street *"
              {...props.register("patient.address.street")}
            />
          </Grid>
          <Grid item xs={2}>
            <TextField
              className="w-100"
              variant="standard"
              name="patient.address.unit"
              inputProps={{ "data-testid": "patient.address.unit", readOnly: patientReadOnly }}
              InputLabelProps={{ shrink: props.watch("patient.address.unit") !== "" }}
              label="Unit"
              {...props.register("patient.address.unit")}
            />
          </Grid>
          <Grid item xs={3}>
            <TextField
              className="w-100"
              variant="standard"
              name="patient.address.city"
              inputProps={{ "data-testid": "patient.address.city", readOnly: patientReadOnly }}
              InputLabelProps={{ shrink: props.watch("patient.address.city") !== "" }}
              error={!!props.errors.patient?.address?.city}
              helperText={props.errors.patient?.address?.city && props.errors.patient?.address?.city.message}
              label="City *"
              {...props.register("patient.address.city")}
            />
          </Grid>
          <Grid item xs={1}>
            <RHFMultiSelect
              name="patient.address.state_id"
              fullWidth
              register={props.register}
              readOnly={patientReadOnly}
              addBlankOption={true}
              control={props.control}
              label="State *"
              options={states}
              optionLabelProperty="code"
              onChange={handleStateChange}
              getOptionLabel={(o) => o?.code || ""}
              getOptionSelected={(o) => o?.code || ""}
              error={!!props.errors.patient?.address?.state_id}
              helperText={props.errors.patient?.address?.state_id && props.errors.patient?.address?.state_id.message}
              objGOS={true}
              inputProps={{ "data-testid": "patient.address.state_id" }}
            />
          </Grid>
          <Grid item xs={1}>
            <TextField
              name="patient.address.zip"
              inputProps={{ "data-testid": "patient.address.zip", readOnly: patientReadOnly }}
              variant="standard"
              InputLabelProps={{ shrink: props.watch("patient.address.zip") !== "" }}
              error={!!props.errors.patient?.address?.zip}
              helperText={props.errors.patient?.address?.zip && props.errors.patient?.address?.zip.message}
              label="ZIP *"
              {...props.register("patient.address.zip")}
            />
          </Grid>
          <Grid item xs={2}>
            <RHFMultiSelect
              name="patient.address.county_id"
              fullWidth
              readOnly={patientReadOnly}
              register={props.register}
              addBlankOption={true}
              label="County of residence *"
              control={props.control}
              options={filteredCounties}
              variant="standard"
              error={!!props.errors.patient?.address?.county_id}
              helperText={props.errors.patient?.address?.county_id && props.errors.patient?.address?.county_id.message}
              objGOS={true}
              inputProps={{ "data-testid": "patient.address.county_id" }}
            />
          </Grid>
        </Grid>

        <PatientSelects {...selectProps} />
      </CardContent>
    </Card>
  );
}
PatientInformation.propTypes = {
  control: PropTypes.object,
  readonly: PropTypes.bool,
  register: PropTypes.func,
  errors: PropTypes.shape({
    patient: PropTypes.shape({
      last_name: PropTypes.object,
      first_name: PropTypes.object,
      dob: PropTypes.object,
      address: PropTypes.shape({
        street: PropTypes.object,
        city: PropTypes.object,
        zip: PropTypes.object,
        county_id: PropTypes.object,
        state_id: PropTypes.object,
      }),
    }),
  }),
};
