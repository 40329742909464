import LoadingButton from "@mui/lab/LoadingButton";
import { Button, Checkbox, FormControl, FormControlLabel, FormGroup } from "@mui/material";
import axios from "axios";
import React, { useEffect } from "react";
import { useState } from "react";
import { useParams } from "react-router-dom";
import api from "../../config/axios.config";
import { useDispatch } from "react-redux";
import { openSnackbar } from "../../store/features/snackbarSlice";

export default function ShowRole() {
  const [privileges, setPrivileges] = useState([]);
  const [role, setRole] = useState("");
  const [readOnly, setReadOnly] = useState(true);
  const [loading, setLoading] = useState(false);
  const [defaultPrivileges, setDefaultPrivileges] = useState([]);
  const params = useParams();
  const dispatch = useDispatch();

  useEffect(() => {
    getRole();
  }, []);

  const getRole = async () => {
    const _privileges = (await api.get("/api/privileges")).data;
    api.get(`/api/roles/${params.id}`).then((resp) => {
      const rolePrivileges = resp.data?.privileges;
      setRole(resp.data?.role?.name);
      _privileges.forEach((privilege, index) => {
        let assigned = false;
        rolePrivileges.forEach((rolePrivilege) => {
          if (privilege.id === rolePrivilege.privilege_id) {
            assigned = true;
            privilege["role_privilege_id"] = rolePrivilege["id"];
          }
        });
        assigned ? (privilege["assigned"] = true) : (privilege["assigned"] = false);
      });
      setPrivileges(_privileges);
      const defaultz = JSON.parse(JSON.stringify(_privileges));
      setDefaultPrivileges(defaultz);
    }); // add error catching?
  };

  const handleChange = (event) => {
    const _priveleges = JSON.parse(JSON.stringify(privileges));
    const newPrivileges = _priveleges.map((privilege) => {
      if (privilege.name === event.target.name) {
        privilege.assigned = event.target.checked;
      }
      return privilege;
    });
    setPrivileges(newPrivileges);
  };

  const handleCancel = () => {
    setPrivileges(defaultPrivileges);
    setReadOnly(true);
  };

  const save = () => {
    const body = {
      privileges: privileges,
      role_id: params.id,
    };
    setLoading(true);
    api
      .patch(`/api/privileges`, body)
      .then((resp) => {
        setLoading(false);
        setReadOnly(true);
        dispatch(openSnackbar({ message: resp.data.message, severity: "success" }));
      })
      .catch((err) => {
        setLoading(false);
        setReadOnly(true);
        dispatch(openSnackbar({ message: "Something went wrong", severity: "error" }));
      });
  };

  return (
    <>
      <div className="flex justify-between">
        <p className="m-0 card-header">{role} - PRIVILEGES</p>
        <div>
          {readOnly && (
            <Button color="secondary" variant="contained" onClick={() => setReadOnly(false)}>
              Edit
            </Button>
          )}
          {!readOnly && (
            <div>
              <Button color="secondary" variant="contained" type="submit" onClick={handleCancel}>
                Cancel
              </Button>
              <LoadingButton className="ml-3" color="secondary" variant="contained" onClick={save} loading={loading}>
                Save
              </LoadingButton>
            </div>
          )}
        </div>
      </div>
      <FormControl sx={{ m: 3 }} component="fieldset" variant="standard">
        <FormGroup>
          {privileges.map((privilege, index) => {
            return (
              <FormControlLabel
                key={index}
                control={<Checkbox checked={privilege.assigned} onChange={handleChange} name={privilege.name} disabled={readOnly} />}
                label={privilege.name}
              />
            );
          })}
        </FormGroup>
      </FormControl>
    </>
  );
}
