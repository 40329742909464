import React from "react";
import { Link } from "react-router-dom";
import { formatDate } from "../../lib/misc";

export const CaseLink = (params) => {
  return <Link to={`/case/${params.id}`}>{params.patient_full_name}</Link>;
};

export const caseColumns = [
  {
    field: "patient_full_name",
    headerName: "Name",
    renderCell: CaseLink,
    width: "15%",
    sortable: false,
  },
  {
    field: "patient_dob",
    headerName: "DOB",
    valueFormatter: formatDate,
    width: "10%",
    sortable: false,
  },
  {
    field: "condition_name",
    headerName: "Condition",
    width: 270,
    width: "20%",
    sortable: false,
  },
  {
    field: "facility_name",
    headerName: "Facility Name",
    flex: true,
    sortable: false,
    width: "15%",
  },
  {
    field: "county_name",
    headerName: "County",
    sortable: false,
    width: "10%",
  },
  {
    field: "specimen_collection_date",
    headerName: "Collection Date",
    valueFormatter: formatDate,
    sortable: false,
    width: "20%",
  },
  {
    field: "created_at",
    headerName: "Created",
    valueFormatter: formatDate,
    sortable: false,
    width: "10%",
  },
];
