import { Button } from "@mui/material";
import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import Dialog from "@mui/material/Dialog";
import DialogTitle from "@mui/material/DialogTitle";

export const Register = ({ setOpen }) => {
  const navigate = useNavigate();
  const [registerDialog, setRegisterDialog] = useState(false);
  const dialogClose = (event, reason) => {
    if (reason !== "backdropClick") {
      setOpen(false);
    }
  };

  return (
    <div>
      <Button
        variant="contained"
        color="primary"
        style={{
          height: "36px",
          padding: "0 16px",
          letterSpacing: ".0892857143em",
        }}
        onClick={() => {
          setRegisterDialog(true);
        }}
      >
        Register new user
      </Button>
      <Dialog open={registerDialog} onClose={dialogClose}>
        <DialogTitle id="alert-dialog-title">END USER AGREEMENT</DialogTitle>
        <DialogContent>
          <DialogContentText className="warningContent" component={"div"}>
          <div>
              I am requesting access to the 'Reportal' to report instances of reportable diseases to the Colorado Department of Public Health and
              Environment (CDPHE). As an employee of a healthcare facility or laboratory required to report to CDPHE, I acknowledge that any
              confidential information entered into the Reportal will be transmitted to CDPHE and may be used for public health investigation. The
              data entered here will be accessible to disease control personnel at CDPHE, facility administrators who are responsible for approving my
              reporting privileges for my facility, and other users from my facility.
            </div>
            <br />
            <div>
              I will notify <a href="mailto:cdphe_reportal_support@state.co.us">cdphe_reportal_support@state.co.us</a> and my facility administrator
              immediately if I am no longer employed with the facility(s) I am reporting for. It's important to note that the Reportal is not intended
              to be the primary source of reporting, but rather to complement conditions that are not already being reported by my facility through
              Electronic Laboratory Reporting (ELR). Any unauthorized disclosure of confidential information entered into the Reportal not only
              threatens the ability of CDPHE to serve the public but is a violation of Colorado and Federal Laws. 
            </div>
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <div style={{ flex: "1 0 0" }} />
          <Button
            color="primary"
            variant="outlined"
            onClick={() => {
              setOpen(false);
              setRegisterDialog(false);
            }}
          >
            Reject
          </Button>
          <Button
            color="secondary"
            variant="outlined"
            onClick={() => {
              setOpen(true);
              setRegisterDialog(false);
            }}
          >
            Accept
          </Button>
          <div style={{ flex: "1 0 0" }} />
        </DialogActions>
      </Dialog>
    </div>
  );
};
