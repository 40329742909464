import React from "react";

import { useForm } from "react-hook-form";

import makeStyles from "@mui/styles/makeStyles";

import Button from "@mui/material/Button";
import Box from "@mui/material/Box";
import Card from "@mui/material/Card";
import CardActions from "@mui/material/CardActions";
import CardContent from "@mui/material/CardContent";
import FormControl from "@mui/material/FormControl";
import TextField from "@mui/material/TextField";
import Typography from "@mui/material/Typography";
import Divider from "@mui/material/Divider";
import axios from "../lib/axios";
import { APP_URL } from "../components/utils/url";

import { useMuiRegister } from "../lib/misc";
import { useNavigate } from "react-router-dom";
import { useDispatch } from "react-redux";
import { openSnackbar } from "../store/features/snackbarSlice";

const useStyles = makeStyles({
  cardTitle: {
    textAlign: "center",
    display: "flex",
    flex: "wrap",
    fontSize: "1.25rem",
    fontWeight: "500",
    letterSpacing: ".0125em",
    lineHeight: "2rem",
    color: "rgba(0,0,0,.6)",
    padding: "16px",
  },

  lightCard: {
    color: "rgba(0,0,0,.6)",
  },

  cardRow: {
    display: "flex",
    flex: "1 1 auto",
    marginRight: "12px",
    marginLeft: "12px",
  },

  cardText: {
    fontSize: ".875rem",
    fontWeight: "400",
    lineHeight: "1.375rem",
    letterSpacing: ".0071428571em",
    marginRight: "12px",
    marginLeft: "12px",
    marginTop: "6px",
  },
});

export default function ForgotPassword() {
  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm();
  const muiRegister = useMuiRegister(register);
  const classes = useStyles();
  const dispatch = useDispatch();

  const doSubmit = (values) => {
    axios
      .post("/session/forgot_password", { email: values.emailAddress })
      .then((resp) => {
        dispatch(openSnackbar({ message: "Please check your email for the password reset link", severity: "success" }));
        navigate(APP_URL.AUTH);
      })
      .catch((err) => {
        if (err) {
          dispatch(openSnackbar({ message: err?.response?.data?.error, severity: "success" }));
        }
      });
  };

  const navigate = useNavigate();

  return (
    <Card variant="outlined" style={{ margin: "16px auto", maxWidth: "800px" }} className={classes.lightCard}>
      <form onSubmit={handleSubmit(doSubmit)}>
        <CardContent>
          <Typography className={classes.cardTitle}>Reset Password</Typography>
          <FormControl fullWidth>
            <TextField
              name="emailAddress"
              label="Email Address *"
              error={errors.emailAddress}
              helperText={errors.emailAddress && errors.emailAddress.message}
              className={classes.cardRow}
              variant="standard"
              {...muiRegister("emailAddress", {
                required: { value: true, message: "Required" },
                pattern: {
                  value: /.+@.+\..+/,
                  message: "Email address must be valid",
                },
              })}
            />
            <Box className={classes.cardText}>Please provide registered email address to receive instructions to reset the password</Box>
          </FormControl>
        </CardContent>
        <Divider />
        <CardActions>
          <div style={{ flex: "1 0 0" }} />
          <Button variant="outlined" color="primary" onClick={() => navigate(APP_URL.AUTH)}>
            CANCEL
          </Button>
          <Button variant="outlined" color="secondary" type="submit">
            RESET
          </Button>
          <div style={{ flex: "1 0 0" }} />
        </CardActions>
      </form>
    </Card>
  );
}
