import * as yup from "yup";
import moment from "moment";

import store from "../store/store";
import config from "../../../config.json";
import { setTreatment } from "../store/features/conditionSlice";

const addressSchema = yup.object().shape({
  street: yup.string().required("Street is required"),
  city: yup.string().required("City is required"),
  zip: yup
    .string()
    .required("Zip Code is required")
    .matches(/^[0-9]{5}(-[0-9]{4})?$/, "Zip code must be ##### or #####-#### format"),
  unit: yup.string().nullable().default(""),
  county_id: yup
    .number()
    .transform((value, originalvalue) => {
      if (originalvalue !== null) {
        value = originalvalue.id;
        return value;
      }
    })
    .required("County is required"),
  state_id: yup
    .number()
    .transform((value, originalvalue) => {
      if (originalvalue !== null) {
        value = originalvalue.id;
        return value;
      }
    })
    .required("State is required"),
});

let patientSchema = yup.object().shape({
  address: addressSchema,
  telephone_type_id: yup
    .number()
    .transform((value, originalvalue) => {
      if (originalvalue !== null) {
        value = originalvalue.id;
        return value;
      }
    })
    .nullable(),
  email: yup.string().nullable().email("Invalid email address"),
  phone_number: yup
    .string()
    .nullable()
    .matches(/^(\d{7})?$/, "Must be seven digits"),
  area_code: yup
    .string()
    .nullable()
    .matches(/^(\d\d\d)?$/, "Must be a three-digit number"),
  gender_id: yup.number().transform((value, originalvalue) => {
    if (originalvalue !== null) {
      value = originalvalue.id;
      return value;
    }
  }),
  sex_id: yup
    .number()
    .transform((value, originalvalue) => {
      if (originalvalue !== null) {
        value = originalvalue.id;
        return value;
      }
    })
    .required("Required")
    .typeError("Please select a sex"),
  race_ids: yup.array().of(yup.number()).nullable(),
  ethnicity_id: yup
    .number()
    .transform((value, originalvalue) => {
      if (originalvalue !== null) {
        value = originalvalue.id;
        return value;
      }
    })
    .nullable(),
  dob: yup
    .string()
    .required("Required")
    .test("", "Please choose a valid date", (value, context) => {
      if (!(moment().diff(moment(value), "years") <= 105)) {
        return context.createError({
          path: "patient.dob",
          message: "Birth year cannot be more than 105 years ago",
        });
      } else if (!(moment().diff(moment(value)) >= 0)) {
        return context.createError({
          path: "patient.dob",
          message: "Cannot be a future date",
        });
      }
      return true;
    }),
  middle_name: yup.string().nullable().default(""),
  first_name: yup.string().required("Required").max(50, "Must be less than 50 characters"),
  last_name: yup.string().required("Required").max(50, "Must be less than 50 characters"),
});

if (config?.patient?.sexualOrientation) {
  patientSchema = patientSchema.concat(
    yup.object().shape({
      sexual_orientation_id: yup
        .number()
        .transform((value, originalvalue) => {
          console.log(value, originalvalue);
          if (originalvalue !== null) {
            value = originalvalue.id;
            return value;
          } else {
            return null;
          }
        })
        .nullable(),
    })
  );
}

let eventSchema = yup.object().shape({
  treatmentStatus: yup.boolean().default(false),
  condition_id: yup
    .number()
    .transform((value, originalvalue) => {
      if (originalvalue !== null) {
        value = originalvalue.id;
        return value;
      }
    })
    .required("Required"),
  facility_visit_type_id: yup.number().transform((value, originalvalue) => {
    if (originalvalue !== null) {
      value = originalvalue.id;
      return value;
    }
  }),
  admitted_date: yup
    .string()
    .nullable()
    .default(null)
    .test("", "Please choose a valid date", (value, context) => {
      if (value !== null && value !== "") {
        if (!(moment().diff(moment(value), "years") <= 5)) {
          return context.createError({
            path: "event.admitted_date",
            message: "Cannot be more than 5 years ago",
          });
        } else if (!(moment().diff(moment(value)) >= 0)) {
          return context.createError({
            path: "event.admitted_date",
            message: "Cannot be a future date",
          });
        }
      }
      return true;
    }),
  discharged_date: yup
    .string()
    .nullable()
    .test("", "Please choose a valid date", (value, context) => {
      if (value !== null && value !== "") {
        if (!(moment().diff(moment(value), "years") <= 5)) {
          return context.createError({
            path: "event.discharged_date",
            message: "Cannot be more than 5 years ago",
          });
        } else if (!(moment().diff(moment(value)) >= 0)) {
          return context.createError({
            path: "event.discharged_date",
            message: "Cannot be a future date",
          });
        }
      }
      return true;
    })
    .test("date-validation", "Discharged date must be later than admitted date", function (value) {
      const admittedDate = this.parent.admitted_date;
      if (value && admittedDate) {
        return value >= admittedDate;
      }
      return true;
    }),
  pregnant: yup
    .boolean()
    .nullable()
    .transform((value, originalvalue) => {
      if (originalvalue !== null) {
        value = originalvalue.value;
        return value;
      } else {
        return null;
      }
    }),
  treatment_status: yup
    .boolean()
    .transform((value, originalvalue) => {
      if (originalvalue?.value) {
        store.dispatch(setTreatment(true));
      } else {
        store.dispatch(setTreatment(false));
      }
      if (originalvalue !== null) {
        value = originalvalue.value;
        return value;
      } else {
        return false;
      }
    })
    .when("treatmentAllowed", {
      is: () => true,
      then: (schema) => schema.required("Required"),
      otherwise: (schema) => schema.nullable(),
    }),
  treatments: yup.array().of(
    yup.object().shape({
      treatment_id: yup
        .number()
        .transform((value, originalvalue) => {
          if (originalvalue !== null) {
            value = originalvalue.id;
            return value;
          } else {
            return null;
          }
        })
        .when("event.treatmentStatus", {
          is: () => true,
          then: (schema) => schema.required("Required"),
          otherwise: (schema) => schema.nullable(),
        }),
      treatment_start_date: yup
        .string()
        .nullable()
        .test("", "Please choose a valid date", (value, context) => {
          if (value !== null && value !== "") {
            if (!(moment().diff(moment(value), "years") <= 5)) {
              return context.createError({
                path: context.path,
                message: "Cannot be more than 5 years ago",
              });
            } else if (!(moment().diff(moment(value)) >= 0)) {
              return context.createError({
                path: context.path,
                message: "Cannot be a future date",
              });
            }
          }
          return true;
        }),
      treatment_end_date: yup
        .string()
        .nullable()
        .test("", "Please choose a valid date", (value, context) => {
          if (value !== null && value !== "") {
            if (!(moment().diff(moment(value), "years") <= 5)) {
              return context.createError({
                path: context.path,
                message: "Cannot be more than 5 years ago",
              });
            }
          }
          return true;
        }),
    })
  ),
});

if (config?.symptoms?.enabled) {
  eventSchema = eventSchema.concat(
    yup.object().shape({
      symptoms: yup.array().of(
        yup.object().shape({
          symptom_id: yup
            .number()
            .transform((value, originalvalue) => {
              if (originalvalue !== null) {
                value = originalvalue.id;
                return value;
              } else {
                return null;
              }
            })
            .nullable(),
          onset_date: yup
            .string()
            .nullable()
            .test("", "Please choose a valid date", (value, context) => {
              if (value !== null && value !== "") {
                if (!(moment().diff(moment(value), "years") <= 5)) {
                  return context.createError({
                    path: context.path,
                    message: "Cannot be more than 5 years ago",
                  });
                } else if (!(moment().diff(moment(value)) >= 0)) {
                  return context.createError({
                    path: context.path,
                    message: "Cannot be a future date",
                  });
                }
              }
              return true;
            }),
        })
      ),
    })
  );
}

let orderSchema = yup.object().shape({
  facility_id: yup
    .number()
    .transform((value, originalvalue) => {
      if (originalvalue !== null) {
        value = originalvalue.id;
        return value;
      }
    })
    .nullable(),
  facility: yup.object().shape({
    name: yup.string().required("Required"),
    address: addressSchema,
    area_code: yup
      .string()
      .nullable()
      .matches(/^(\d\d\d)?$/, "Must be a three-digit number"),
    phone_number: yup
      .string()
      .nullable()
      .matches(/^(\d{7})?$/, "Must be seven digits"),
  }),
  clinician_first_name: yup.string().nullable(),
  clinician_last_name: yup.string().nullable(),
  specimen_source_id: yup.number().transform((value, originalvalue) => {
    if (originalvalue !== null) {
      value = originalvalue.id;
      return value;
    }
  }),
  specimen_collection_date: yup
    .string()
    .required("Specimen collection date is required")
    .test("", "Please choose a valid date", (value, context) => {
      if (value !== null && value !== "") {
        if (!(moment().diff(moment(value), "years") <= 5)) {
          return context.createError({
            path: "order.specimen_collection_date",
            message: "Cannot be more than 5 years ago",
          });
        } else if (!(moment().diff(moment(value)) >= 0)) {
          return context.createError({
            path: "order.specimen_collection_date",
            message: "Cannot be a future date",
          });
        }
      }
      return true;
    }),
});

if (config?.order?.testingReason) {
  orderSchema = orderSchema.concat(
    yup.object().shape({
      testing_reason_id: yup
        .number()
        .transform((value, originalvalue) => {
          if (originalvalue !== null) {
            value = originalvalue.id;
            return value;
          }
        })
        .nullable(),
    })
  );
}

const labTestBaseSchema = () => {
  return yup.object().shape({
    lab: yup.object().shape({
      name: yup.string().required("Lab is required"),
      address: addressSchema,
      area_code: yup
        .string()
        .nullable()
        .matches(/^(\d\d\d)?$/, "Must be a three-digit number"),
      phone_number: yup
        .string()
        .nullable()
        .matches(/^(\d{7})?$/, "Must be seven digits"),
    }),
    accession_number: yup.string().nullable(),
    test_date: yup
      .string()
      .required("Test date is required")
      .test({
        name: "empty_test_date",
        test: (value) => value === null || value === undefined || value === "",
        message: "No specimen sent",
      }),
    lab_test_results: yup.array().of(
      yup.object().shape({
        test_type_id: yup
          .number()
          .transform((value, originalvalue) => {
            if (originalvalue !== null) {
              value = originalvalue.id;
              return value;
            }
          })
          .required("Test type is required"),
        test_result_id: yup
          .number()
          .transform((value, originalvalue) => {
            if (originalvalue !== null) {
              value = originalvalue.id;
              return value;
            }
          })
          .required("Test result is required"),
        result_value: yup.string().nullable(),
      })
    ),
  });
};

export let formSchema = (noLabRequired, treatmentAllowed, currentUser) => {
  let schema = yup.object().shape({
    facility: yup.object().shape({
      address: addressSchema,
      name: yup.string().required("Required"),
    }),
    facility_id: yup
      .number()
      .transform((value, originalvalue) => {
        if (originalvalue !== null) {
          value = originalvalue.id;
          return value;
        }
      })
      .required("Required"),
    user_id: yup.number().default(currentUser.id),
    clinician: yup.object().shape({
      area_code: yup
        .string()
        .nullable()
        .matches(/^(\d\d\d)?$/, "Must be a three-digit number")
        .required("Required"),
      phone_number: yup
        .string()
        .nullable()
        .matches(/^(\d{7})?$/, "Must be seven digits")
        .required("Required"),
      first_name: yup.string().nullable().default("").required("Required"),
      last_name: yup.string().nullable().default("").required("Required"),
    }),
    patient_search: yup
      .object()
      .shape({
        last_name: yup.string(),
        first_name: yup.string(),
        dob: yup.string(),
      })
      .nullable(),
    patient_id: yup
      .number()
      .transform((value, originalvalue) => {
        if (originalvalue !== null) {
          value = originalvalue.id;
          return value;
        }
      })
      .nullable(),
    patient: patientSchema,
    noLabReported: yup.boolean().default(false),
    order: orderSchema,
    treatmentAllowed: yup.boolean(),
    event: eventSchema,
    lab_test: yup.object().when("order.specimen_collection_date", ([specimen_collection_date], schema) => {
      if (!specimen_collection_date) {
        return labTestBaseSchema();
      }

      return labTestBaseSchema().shape({
        test_date: yup
          .string()
          .required("Required when test results have been received")
          .test({
            name: "greater_test_date",
            test: (value) => !!value && moment(value).isSameOrAfter(specimen_collection_date),
            message: "Test date cannot be before specimen sent date",
          }),
      });
    }),
    notes: yup.string().max(200, "Must be less than 200 characters"),
  });

  return schema;
};
