export const APP_URL = {
  // Protected
  DASHBOARD: "/",
  NEW_CASE: "/newcase",
  BULK_UPLOAD: "/bulkuploads",
  VIEW_CASE: (id = ":caseId", submitted = false) => (submitted ? `/case/${id}?submitted=true` : `/case/${id}`),
  USERS: "/users",
  VIEW_USER: "/user/:id",
  FACILITIES: "/facilities",
  VIEW_FACILITY: "/facility/:id",
  ROLES: "/roles",
  VIEW_ROLE: "/role/:id",
  CONDTIONS: "/conditions",
  VIEW_CONDITION: "/condition/:conditionId",
  ERROR_SUBMISSION: "/error-submission",
  VIEW_ERROR_SUBMISSION: "/error-submission/:errorId",

  // Open
  AUTH: "/auth",
  FORGOT_PASSWORD: "/forgot-password",
  PASSWORD_RESET: "/password-reset/:token",
};

export const API_URL = {
  USER_SIGNIN: "session/sign_in",
  CURRENT_USER: "/notifications/me.json",
  GET_SOURCES: "/notifications/source",
  GET_CASE_SEARCH_OPTIONS: "/api/case-search-options",
  GET_CASES: "/notifications/search.json",
  GET_CASE_DROPDOWN_OPTIONS: "/api/case-dropdown-options",
  PATIENT_SEARCH: "patient/search",
  GET_CASE: (id) => `notifications/${id}`,
};

export const NO_AUTH_URLS = [APP_URL.AUTH, APP_URL.FORGOT_PASSWORD, "/password-reset"];
