import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  patients: [],
  searched: false,
  patientSelected: false,
  patientId: null,
};

const patientSearchSlice = createSlice({
  name: "patientSearch",
  initialState,
  reducers: {
    setPatientSearchResults(state, action) {
      state.patients = action.payload;
    },
    setPatientsSearched(state, action) {
      state.searched = action.payload;
    },
    setPatientSelected(state, action) {
      state.patientSelected = action.payload;
    },
    setPatientId(state, action) {
      state.patientId = action.payload;
    },
  },
});

export const {
  setPatientSearchResults,
  setPatientsSearched,
  setPatientSelected,
  setPatientId,
} = patientSearchSlice.actions;

export default patientSearchSlice.reducer;
