import { useEffect, useState } from "react";
import { useDispatch } from "react-redux";

import api from "../lib/axios";
import { API_URL } from "../components/utils/url";
import { setCaseDropdownData } from "../store/features/caseDropdownSlice";
import { setDropdownDataLoading } from "../store/features/caseSlice";

export const useNewCaseOptions = () => {
  const [dropDownData, setDropDownData] = useState({});
  const dispatch = useDispatch();
  useEffect(() => {
    getData();
  }, []);

  const getData = async () => {
    dispatch(setDropdownDataLoading(true));
    try {
      const newCaseOptionsRes = await api.get(API_URL.GET_CASE_DROPDOWN_OPTIONS);
      if (newCaseOptionsRes) {
        setDropDownData(newCaseOptionsRes.data);
        dispatch(setCaseDropdownData(newCaseOptionsRes.data));
        dispatch(setDropdownDataLoading(false));
      }
    } catch (err) {
      dispatch(setDropdownDataLoading(false));
    }
  };

  return dropDownData;
};
