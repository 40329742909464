// snackbarSlice.js
import { createSlice } from "@reduxjs/toolkit";

const labSlice = createSlice({
  name: "lab",
  initialState: {
    labSelected: false,
  },
  reducers: {
    setLabSelected: (state, action) => {
      state.labSelected = action.payload;
    },
  },
});

export const { setLabSelected } = labSlice.actions;
export default labSlice.reducer;
