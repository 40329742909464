import React from "react";
import { useDispatch } from "react-redux";
import { useForm } from "react-hook-form";
import Card from "@mui/material/Card";
import CardContent from "@mui/material/CardContent";
import Grid from "@mui/material/Grid";
import TextField from "@mui/material/TextField";
import { Button } from "@mui/material";

import { API_URL } from "../../utils/url";
import { setPatientSearchResults, setPatientsSearched } from "../../../store/features/patientSearchSlice";
import axios from "axios";

export default function SearchOptions() {
  const { register, reset, getValues } = useForm();
  const dispatch = useDispatch();

  const search = async () => {
    const data = getValues();
    const params = {
      first_name: data.first_name,
      last_name: data.last_name,
      dob: data.dob,
    };
    const searchResults = await axios.get(API_URL.PATIENT_SEARCH, { params });
    dispatch(setPatientSearchResults(searchResults?.data));
    dispatch(setPatientsSearched(true));
  };

  return (
    <Card className="mt-3" variant="outlined">
      <CardContent>
        <form>
          <p className="m-0 card-header">PATIENT SEARCH</p>
          <Grid className="pt-3" container spacing={2}>
            <Grid item xs={3}>
              <TextField className="w-100" name="last_name" label="Last Name" variant="standard" {...register("last_name")} />
            </Grid>
            <Grid item xs={3}>
              <TextField className="w-100" name="first_name" label="First Name" variant="standard" {...register("first_name")} />
            </Grid>
            <Grid item xs={3}>
              <TextField
                className="w-100"
                variant="standard"
                name="dob"
                type="date"
                InputLabelProps={{ shrink: true }}
                pattern="\d{4}-\d{2}-\d{2}"
                label="Date of Birth"
                {...register("dob")}
              />
            </Grid>
            <Grid container item xs={2} justifyContent="space-between">
              <Grid item>
                <Button variant="contained" color="primary" onClick={() => reset()}>
                  Reset
                </Button>
              </Grid>
              <Grid item>
                <Button variant="contained" color="secondary" onClick={() => search()}>
                  Search
                </Button>
              </Grid>
            </Grid>
          </Grid>
        </form>
      </CardContent>
    </Card>
  );
}
