import React from "react";
import makeStyles from "@mui/styles/makeStyles";
import { useSelector } from "react-redux";
import { useState } from "react";

const useStyles = makeStyles({
  banner: {
    padding: "0px !important",
    width: "100% !important",
  },
  bannerWithHeight: {
    padding: "0px !important",
    width: "100% !important",
    height: "100px",
    margin: "1rem",
  },
});

export default function PortalHeader() {
  const [headerPresent, setHeaderPresent] = useState(true);
  const [imgSrc, setImgSrc] = useState(`${process.env.RELATIVE_URI}state-assets/images/portal-header.jpg`);
  const [imgExtIndex, setImgExtIndex] = useState(0);
  const { config } = useSelector((state) => state.config);
  const classes = useStyles();

  const state = process.env.DEFAULT_STATE;
  const extensions = ["jpg", "png", "svg"];

  const handleError = () => {
    const nextIndex = imgExtIndex + 1;
    if (nextIndex < extensions.length) {
      setImgExtIndex(nextIndex);
      setImgSrc(`${process.env.RELATIVE_URI}state-assets/images/portal-header.${extensions[nextIndex]}`);
    } else {
      setHeaderPresent(false);
    }
  };

  const getImageClass = () => {
    if (process.env.DEFAULT_STATE === "CO") {
      return classes.bannerWithHeight;
    } else {
      return classes.banner;
    }
  };

  return (
    <>
      {headerPresent && (
        <div>
          <img className={getImageClass()} src={imgSrc} alt={`${state} header`} onError={handleError} />
        </div>
      )}
      {config?.header?.headerTitle && <p className="header-title">{config.header.headerTitle}</p>}
    </>
  );
}
