import React from 'react'
import PropTypes from 'prop-types'

import { Controller } from 'react-hook-form'

import { FormControl, FormHelperText, TextField } from '@mui/material'
import Autocomplete from '@mui/material/Autocomplete'

/*
 * Select component that works with Material-UI and react-hook-form. See
 * propTypes declaration below for documentation on each prop
 */

export default function RHFMultiSelect (props) {
  const changeMe = (e, n, f) => {
    if (props.selectAll && n.length > 0 && n[n.length - 1].id && n[n.length - 1].id === 'selectall') {
      f(props.options)
    } else { f(n) }
  }

  let options = []
  if (props.selectAll) {
    options.push({ id: 'selectall', name: 'Select all' })
  }
  if (props.addBlankOption) {
    options.push('')
  }
  options = options.concat(props.options)

  const getOptionSelected =
    props.getOptionSelected || (
      props.objGOS
        ? (a, b) => a === b || ({}.hasOwnProperty.call(b, 'id') ? b.id === a.id : b === a?.name)
        : (a, b) => a === b
    )

  let readCase = (params) => { return ({ ...params.InputProps }) }
  if (props.readOnly) {
    readCase = (params) => { return ({ readOnly: true }) }
  }

  return (
    <Controller
      control={props.control}
      name={props.name}
      defaultValue={[]}
      render={({ field, formState }) => {
        const { onChange, onBlur, value } = field
        return (
          <FormControl fullWidth={props.fullWidth} error={props.error}>
            <Autocomplete
              multiple={props.multiple}
              id="tags-standard"
              options={options}
              onBlur={onBlur}
              freeSolo={props.freeSolo}
              disabled={props.disabled}
              readOnly={props.readOnly}
              isOptionEqualToValue={ getOptionSelected }
              { ...props.autocompleteProps }
              getOptionLabel={props.getOptionLabel || (option => option?.name || 'Please select')}
              // defaultValue={[]}
              value={value}
              onChange={props.onChange || ((e, n) => changeMe(e, n, onChange))}
              limitTags={2}
              renderOption={(props, option) => {
                return (
                  <li {...props} key={option.id}>
                    {option.name}
                  </li>
                );
              }}
              renderInput={(params) => (
                <TextField
                  {...params}
                  variant="standard"
                  label={props.label}
                  onChange={props.onTextChange}
                  disabled={props.disabled}
                  InputProps={readCase(params)}
                  placeholder={props.placeholder}
                  inputProps={{...props.inputProps, ...params.inputProps}}
                />
              )}
            />
            { props.helperText && (<FormHelperText>{props.helperText}</FormHelperText>) }
          </FormControl>
        )
      }}
    />
  )
}
RHFMultiSelect.propTypes = {
  options: PropTypes.array, // The options to have in the select box
  selectAll: PropTypes.bool, // Whether to add a "Select all" option
  control: PropTypes.object, // react-hook-form's control object
  name: PropTypes.string, // Name of the field
  autocompleteProps: PropTypes.object, // Any extra props to the MUI Autocomplete object
  label: PropTypes.string, // Label to be shown in the UI for this field
  placeholder: PropTypes.string, // Placeholder text, replaced by selected value(s)
  addBlankOption: PropTypes.bool, // Whether to add a NULL item to the options list
  readOnly: PropTypes.bool, // Whether this field is readonly or not
  register: PropTypes.func, // react-hook-form's register function
  multiple: PropTypes.bool, // Whether to allow multiple selects
  fullWidth: PropTypes.bool, // Whether to apply FormControl fullwidth
  getOptionLabel: PropTypes.func, // Custom getOptionLabel function
  getOptionSelected: PropTypes.func, // Custom getOptionSelected function
  objGOS: PropTypes.bool,
  onChange: PropTypes.func,
  onTextChange: PropTypes.func,
  // Whether to use an alternative getOptionSelected routine, which works
  // better with options consisting of objects with `id` and `name` properties
  error: PropTypes.bool, // True if there are validation errors on this field
  helperText: PropTypes.string // An optional helper message, such as validation output
}
