import React, { useEffect } from "react";
import { Button, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, TextField } from "@mui/material";
import axios from "axios";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogTitle from "@mui/material/DialogTitle";
import { useState } from "react";
import { Link } from "react-router-dom";
import { pathname } from "../../lib/misc";
import api from "../../config/axios.config";
import { useSelector, useDispatch } from "react-redux";
import { openSnackbar } from "../../store/features/snackbarSlice";

export default function RoleManagement() {
  const [roles, setRoles] = useState([]);
  const [dialog, setDialog] = useState(false);
  const [roleName, setRoleName] = useState("");
  const { currentUser } = useSelector((state) => state.session);
  const dispatch = useDispatch();

  const linkToCase = (params) => {
    return <Link to={"/role/" + params.id}>{params.name}</Link>;
  };

  const columns = [
    {
      field: "name",
      headerName: "Role",
      renderCell: linkToCase,
      width: "15%",
      sortable: false,
    },
  ];

  useEffect(() => {
    getRoles();
  }, []);

  const handleClose = () => {
    setDialog(false);
    setRoleName("");
  };

  const checkDisabled = () => {
    let disabled = true;
    if (roleName.length > 0) {
      disabled = false;
    }
    return disabled;
  };

  const createRole = () => {
    const body = {
      role: {
        name: roleName,
        code: roleName.split(" ").join("_"),
        created_by: currentUser.id,
      },
    };
    api
      .post("/api/roles", body)
      .then((resp) => {
        handleClose();
        dispatch(openSnackbar({ message: resp.data?.message, severity: "success" }));
        getRoles();
      })
      .catch((err) => {
        handleClose();
        dispatch(openSnackbar({ message: "Something went wrong", severity: "error" }));
      });
  };

  const getRoles = () => {
    api.get("/api/roles").then((resp) => {
      setRoles(resp.data);
    });
  };

  return (
    <>
      <p className="main-header m-0 text-center pb-3">Role Management</p>
      <div className="flex justify-end py-3">
        <Button color="secondary" variant="contained" onClick={() => setDialog(true)}>
          Create Role
        </Button>
        <Dialog
          open={dialog}
          onClose={handleClose}
          fullWidth
          maxWidth="sm"
          aria-labelledby="alert-dialog-title"
          aria-describedby="alert-dialog-description"
        >
          <DialogTitle id="alert-dialog-title">{"Create Role"}</DialogTitle>
          <DialogContent>
            <TextField
              autoFocus
              margin="dense"
              id="role"
              label="Role Name"
              type="text"
              fullWidth
              value={roleName}
              onChange={() => setRoleName(event.target.value)}
              variant="standard"
            />
          </DialogContent>
          <DialogActions>
            <Button onClick={handleClose}>Cancel</Button>
            <Button disabled={checkDisabled()} onClick={createRole}>
              Create
            </Button>
          </DialogActions>
        </Dialog>
      </div>
      <TableContainer>
        <Table size="medium" stickyHeader>
          <colgroup>
            {columns.map((column, index) => (
              <col key={index} width={column.width} />
            ))}
          </colgroup>
          <TableHead className="tableHeader">
            <TableRow>
              {columns.map((column, index) => (
                <TableCell key={index} align="left">
                  {column.headerName}
                </TableCell>
              ))}
            </TableRow>
          </TableHead>
          <TableBody>
            {roles.map((role, index) => {
              return (
                <TableRow key={index} style={{ height: 10 }}>
                  {columns.map((column, index) => {
                    return column?.renderCell ? (
                      <TableCell align="left" key={index}>
                        {column.renderCell(role)}
                      </TableCell>
                    ) : (
                      <TableCell align="left" key={index}>
                        {role[column.field]}
                      </TableCell>
                    );
                  })}
                </TableRow>
              );
            })}
          </TableBody>
        </Table>
      </TableContainer>
    </>
  );
}
