import React from 'react'
import UserResults from './UserResults'
import UserSearch from './UserSearch'

export default function UserManagement () {
  return (
      <>
        <p className='main-header m-0 text-center pb-3'>User Management</p>
        <UserSearch /> 
        <UserResults />
      </>
  )
}
