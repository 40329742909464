import { PRIVILEGES } from "../../constants/roles.constants";
import { checkPrivilege } from "../../helpers/privilegesHelper";
import { APP_URL } from "./url";

export const appMenu = [
  {
    id: 1,
    name: "Dashboard",
    path: APP_URL.DASHBOARD,
  },
  {
    id: 2,
    name: "New Case",
    path: APP_URL.NEW_CASE,
  },
  {
    id: 3,
    name: "Bulk Uploads",
    path: APP_URL.BULK_UPLOAD,
  },
  {
    id: 4,
    name: "Error Submission",
    path: APP_URL.ERROR_SUBMISSION,
  },
];

export const adminMenu = (currentUser) => {
  return [
    {
      id: 1,
      name: "Users",
      show: checkPrivilege(currentUser, PRIVILEGES.MANAGE_USERS) || checkPrivilege(currentUser, PRIVILEGES.MANAGE_FACILITY_USERS),
      path: APP_URL.USERS,
    },
    {
      id: 2,
      name: "Facilities",
      show: checkPrivilege(currentUser, PRIVILEGES.VIEW_USER_FACILITIES) || checkPrivilege(currentUser, PRIVILEGES.MANAGE_FACILITIES),
      path: APP_URL.FACILITIES,
    },
    {
      id: 3,
      name: "Conditions",
      show: checkPrivilege(currentUser, PRIVILEGES.MANAGE_CONDITIONS),
      path: APP_URL.CONDTIONS,
    },
    {
      id: 4,
      name: "Roles",
      show: checkPrivilege(currentUser, PRIVILEGES.MANAGE_ROLES),
      path: APP_URL.ROLES,
    },
  ];
};
