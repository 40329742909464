import React from "react";
import { Table, TableBody, TableCell, TableContainer, TableHead, TableRow } from "@mui/material";
import { useDispatch, useSelector } from "react-redux";
import { setPatientId, setPatientSelected } from "../../../store/features/patientSearchSlice";
import { formatDate, formatPhone } from "../../../lib/misc";
import { setSelectedRaces } from "../../../store/features/patientSlice";

export default function PatientSearchResults(props) {
  const { patients } = useSelector((state) => state.patientSearch);
  const dispatch = useDispatch();
  const linkToCase = (params) => {
    return <p>{params.full_name}</p>;
  };

  const columns = [
    { field: "full_name", headerName: "Name", renderCell: linkToCase },
    {
      field: "dob",
      headerName: "Date of Birth",
      valueFormatter: formatDate,
    },
    { field: "sex_name", headerName: "Sex" },
  ];

  const selectPatient = (patient) => {
    dispatch(setPatientSelected(true));
    dispatch(setPatientId(patient.id));
    const setValue = props.setValue;
    console.log(patient);
    setValue("patient_id", patient);
    setValue("patient.first_name", patient.first_name);
    setValue("patient.last_name", patient.last_name);
    setValue("patient.middle_name", patient.middle_name);
    setValue("patient.dob", patient.dob);
    setValue("patient.email", patient.email || "");
    setValue("patient.gender_id", patient.gender);
    setValue("patient.sex_id", patient.sex);
    setValue("patient.ethnicity_id", patient.ethnicity);
    setValue("patient.telephone_type_id", patient.telephone_type);
    setValue("patient.address.street", patient.address.street);
    setValue("patient.address.city", patient.address.city);
    setValue("patient.address.zip", patient.address.zip);
    setValue("patient.address.unit", patient.address.unit);
    setValue("patient.address.county_id", patient.address.county);
    setValue("patient.address.state_id", patient.address.state);
    setValue("patient.area_code", patient.area_code);
    setValue("patient.phone_number", patient.phone_number);
    setValue("patient.phone", formatPhone(patient.area_code, patient.phone_number));
    const races = patient.race?.map((race) => race.name);
    dispatch(setSelectedRaces(races));
    props.clearErrors("patient");
  };

  return (
    <TableContainer style={{ maxHeight: 200 }}>
      <Table sx={{ minWidth: 650 }} aria-label="simple table" stickyHeader={true}>
        <TableHead>
          <TableRow>
            {columns.map((column, index) => (
              <TableCell key={index} style={{ paddingRight: "0px", paddingLeft: "0px" }}>
                {column.headerName}
              </TableCell>
            ))}
          </TableRow>
        </TableHead>
        {patients.length > 0 ? (
          <TableBody>
            {patients.map((patient, index) => {
              return (
                <TableRow className="cursor-pointer patient-row" key={index} onClick={() => selectPatient(patient)}>
                  {columns.map((column, index) => {
                    return column?.renderCell ? (
                      <TableCell style={{ padding: "0px" }} key={index}>
                        {column.renderCell(patient)}
                      </TableCell>
                    ) : column?.valueFormatter ? (
                      <TableCell style={{ padding: "0px" }} key={index}>
                        {formatDate(patient?.[column?.field])}
                      </TableCell>
                    ) : (
                      <TableCell style={{ padding: "0px" }} key={index}>
                        {patient[column.field]}
                      </TableCell>
                    );
                  })}
                </TableRow>
              );
            })}
          </TableBody>
        ) : (
          <TableBody>
            <TableCell colSpan={3} align="center">
              No match found.
            </TableCell>
          </TableBody>
        )}
      </Table>
    </TableContainer>
  );
}
