import React, { useEffect } from "react";
import { Button, Table, TableBody, TableCell, FormControlLabel, Checkbox, TableContainer, TableHead, TableRow, Grid, TextField } from "@mui/material";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogTitle from "@mui/material/DialogTitle";
import { useState } from "react";
import { Link } from "react-router-dom";
import { pathname } from "../../lib/misc";
import api from "../../config/axios.config";
import { useDispatch, useSelector } from "react-redux";
import { openSnackbar } from "../../store/features/snackbarSlice";

export default function ConditionManagement() {
  const [conditions, setConditions] = useState([]);
  const [conditionName, setConditionName] = useState("");
  const [conditionCode, setConditionCode] = useState("");
  const [dialog, setDialog] = useState(false);
  const { currentUser } = useSelector((state) => state.session);
  const [labreportRequired, setLabreportRequired] = useState(false);
  const [notifiable, setNotifiable] = useState(false);
  const [treatmentAllowed, setTreatmentAllowed] = useState(false);
  const dispatch = useDispatch();

  const linkToCase = (params) => {
    return <Link to={"/condition/" + params.id}>{params.name}</Link>;
  };

  const columns = [
    {
      field: "name",
      headerName: "Condition Name",
      renderCell: linkToCase,
      width: "50%",
      sortable: false,
    },
    { field: "code", headerName: "Code", width: "50%", sortable: false },
  ];

  useEffect(() => {
    getConditions();
  }, []);

  const handleClickOpen = (facility) => {
    setDialog(true);
  };

  const handleClose = () => {
    setDialog(false);
    resetInputs();
  };

  const resetInputs = () => {
    setConditionName("");
    setConditionCode("");
  };

  const checkDisabled = () => {
    let disabled = true;
    if (conditionName.length > 0) {
      disabled = false;
    }
    return disabled;
  };

  const createCondition = () => {
    const body = {
      condition: {
        name: conditionName,
        code: conditionCode,
        is_lab_report_required: labreportRequired,
        is_treatment_allowed: treatmentAllowed,
        is_notifiable: notifiable,
        user_id: currentUser.id,
      },
    };
    api
      .post("/api/conditions", body)
      .then((resp) => {
        handleClose();
        dispatch(openSnackbar({ message: resp.data?.message, severity: "success" }));
        getConditions();
      })
      .catch((err) => {
        handleClose();
        dispatch(openSnackbar({ message: "Something went wrong", severity: "error" }));
      });
  };

  const getConditions = () => {
    api.get("/api/conditions").then((resp) => {
      setConditions(resp.data);
    });
  };

  return (
    <>
      <p className="main-header m-0 text-center pb-3">Condition Management</p>
      <div className="flex justify-end py-3">
        <Button color="secondary" variant="contained" onClick={() => setDialog(true)}>
          Create New Condition
        </Button>
        <Dialog
          open={dialog}
          onClose={handleClose}
          fullWidth
          maxWidth="sm"
          aria-labelledby="alert-dialog-title"
          aria-describedby="alert-dialog-description"
        >
          <DialogTitle id="alert-dialog-title">{"Create New Condition"}</DialogTitle>
          <DialogContent>
            <TextField
              autoFocus
              margin="dense"
              id="name"
              label="Condition Name *"
              type="text"
              fullWidth
              value={conditionName}
              onChange={() => setConditionName(event.target.value)}
              variant="standard"
            />
            <TextField
              margin="dense"
              id="code"
              label="Condition Code"
              type="number"
              value={conditionCode}
              onChange={() => setConditionCode(event.target.value)}
              variant="standard"
            />
            <Grid className="pt-3" item xs={3}>
              <FormControlLabel
                control={
                  <Checkbox name="is_lab_report_required" checked={labreportRequired} onChange={(e) => setLabreportRequired(e.target.checked)} />
                }
                label="Lab report required"
              />
            </Grid>
            <Grid className="pt-3" item xs={3}>
              <FormControlLabel
                control={<Checkbox name="is_treatment_allowed" checked={treatmentAllowed} onChange={(e) => setTreatmentAllowed(e.target.checked)} />}
                label="Treatment allowed"
              />
            </Grid>
            <Grid className="pt-3" item xs={3}>
              <FormControlLabel
                control={<Checkbox name="is_notifiable" checked={notifiable} onChange={(e) => setNotifiable(e.target.checked)} />}
                label="Notifiable"
              />
            </Grid>
          </DialogContent>
          <DialogActions>
            <Button onClick={handleClose}>Cancel</Button>
            <Button disabled={checkDisabled()} onClick={createCondition}>
              Create
            </Button>
          </DialogActions>
        </Dialog>
      </div>
      <TableContainer>
        <Table size="medium" stickyHeader>
          <colgroup>
            {columns.map((column, index) => (
              <col key={index} width={column.width} />
            ))}
          </colgroup>
          <TableHead className="tableHeader">
            <TableRow>
              {columns.map((column, index) => (
                <TableCell key={index} align="left">
                  {column.headerName}
                </TableCell>
              ))}
            </TableRow>
          </TableHead>
          <TableBody>
            {conditions.map((condition, index) => {
              return (
                <TableRow key={index} style={{ height: 10 }}>
                  {columns.map((column, index) => {
                    return column?.renderCell ? (
                      <TableCell align="left" key={index}>
                        {column.renderCell(condition)}
                      </TableCell>
                    ) : (
                      <TableCell align="left" key={index}>
                        {condition[column.field]}
                      </TableCell>
                    );
                  })}
                </TableRow>
              );
            })}
          </TableBody>
        </Table>
      </TableContainer>
    </>
  );
}
