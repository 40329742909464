import React from "react";
import { Route, Routes } from "react-router-dom";

import { ProtectedRoute } from "./ProtectedRoute";
import { APP_URL } from "../utils/url";
import ForgotPassword from "../ForgotPassword";
import { Auth } from "../auth/Auth";
import PasswordReset from "../PasswordReset";
import { Dashboard } from "../dashboard/Dashboard";
import BulkUpload from "../BulkUpload";
import UserManagement from "../user_management/UserManagement";
import FacilityManagement from "../facility_management/FacilityManagement";
import ShowUser from "../user_management/ShowUser";
import RoleManagement from "../role_management/RoleManagement";
import ShowFacility from "../facility_management/ShowFacility";
import ShowRole from "../role_management/ShowRole";
import ConditionManagement from "../condition_management/ConditionManagement";
import ShowCondition from "../condition_management/ShowCondition";
import ErrorSubmission from "../ErrorSubmission";
import { Case } from "../case/Case";

export const Outlet = (props) => {
  return (
    <Routes>
      <Route path={APP_URL.AUTH} element={<Auth />} />
      <Route path={APP_URL.FORGOT_PASSWORD} element={<ForgotPassword />} />
      <Route path={APP_URL.PASSWORD_RESET} element={<PasswordReset />} />
      {/* Main Menu */}
      <Route
        path={APP_URL.DASHBOARD}
        element={
          <ProtectedRoute>
            <Dashboard />
          </ProtectedRoute>
        }
      />
      <Route
        path={APP_URL.NEW_CASE}
        index={true}
        element={
          <ProtectedRoute>
            <Case />
          </ProtectedRoute>
        }
      />
      <Route
        path={APP_URL.VIEW_CASE()}
        element={
          <ProtectedRoute>
            <Case {...props} />
          </ProtectedRoute>
        }
      />
      <Route
        path={APP_URL.BULK_UPLOAD}
        element={
          <ProtectedRoute>
            <BulkUpload />
          </ProtectedRoute>
        }
      />

      {/* Admin Functionality */}
      <Route
        path={APP_URL.USERS}
        element={
          <ProtectedRoute>
            <UserManagement />
          </ProtectedRoute>
        }
      />
      <Route
        path={APP_URL.VIEW_USER}
        element={
          <ProtectedRoute>
            <ShowUser />
          </ProtectedRoute>
        }
      />
      <Route
        path={APP_URL.FACILITIES}
        element={
          <ProtectedRoute>
            <FacilityManagement />
          </ProtectedRoute>
        }
      />
      <Route
        path={APP_URL.ROLES}
        element={
          <ProtectedRoute>
            <RoleManagement />
          </ProtectedRoute>
        }
      />
      <Route
        path={APP_URL.VIEW_FACILITY}
        element={
          <ProtectedRoute>
            <ShowFacility />
          </ProtectedRoute>
        }
      />
      <Route
        path={APP_URL.VIEW_ROLE}
        element={
          <ProtectedRoute>
            <ShowRole />
          </ProtectedRoute>
        }
      />
      <Route
        path={APP_URL.CONDTIONS}
        element={
          <ProtectedRoute>
            <ConditionManagement />
          </ProtectedRoute>
        }
      />
      <Route
        path={APP_URL.VIEW_CONDITION}
        element={
          <ProtectedRoute>
            <ShowCondition />
          </ProtectedRoute>
        }
      />
      <Route
        path={APP_URL.ERROR_SUBMISSION}
        element={
          <ProtectedRoute>
            <ErrorSubmission />
          </ProtectedRoute>
        }
      />
      <Route
        path={APP_URL.VIEW_ERROR_SUBMISSION}
        element={
          <ProtectedRoute>
            <ErrorSubmission />
          </ProtectedRoute>
        }
      />
    </Routes>
  );
};
