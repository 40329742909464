import React, { useEffect } from "react";
import { Button } from "@mui/material";
import { useDispatch, useSelector } from "react-redux";

import PatientSearchResults from "./PatientSearchResults";
import SearchOptions from "./SearchOptions";
import { setPatientSelected, setPatientsSearched } from "../../../store/features/patientSearchSlice";

export const PatientSearch = (props) => {
  const { searched } = useSelector((state) => state.patientSearch);
  const dispatch = useDispatch();
  const resetPatient = () => {
    const setValue = props.setValue;
    setValue("patient_id", null);
    setValue("patient.first_name", "");
    setValue("patient.last_name", "");
    setValue("patient.middle_name", "");
    setValue("patient.dob", "");
    setValue("patient.email", "");
    setValue("patient.gender_id", null);
    setValue("patient.sex_id", null);
    setValue("patient.race_ids", []);
    setValue("patient.ethnicity_id", null);
    setValue("patient.telephone_type_id", null);
    setValue("patient.address.street", "");
    setValue("patient.address.city", "");
    setValue("patient.address.zip", "");
    setValue("patient.address.unit", "");
    setValue("patient.address.county_id", null);
    setValue("patient.area_code", "");
    setValue("patient.phone_number", "");
  };

  useEffect(() => {
    resetPatient();
    dispatch(setPatientsSearched(false));
  }, []);
  return (
    <>
      <SearchOptions />
      {searched && <PatientSearchResults {...props} />}
      {searched && (
        <div className="pt-1 flex justify-end">
          <Button
            onClick={() => {
              dispatch(setPatientsSearched(false));
              dispatch(setPatientSelected(false));
              resetPatient();
            }}
            variant="contained"
            color="secondary"
          >
            Reset
          </Button>
        </div>
      )}
    </>
  );
};
