import React, { useEffect } from "react";
import { Table, TableBody, TableCell, TableContainer, TableFooter, TableHead, TablePagination, TableRow } from "@mui/material";
import { getUsers } from "../../helpers/userManagementHelper";
import { useSelector } from "react-redux";
import { Link } from "react-router-dom";
import { pathname } from "../../lib/misc";
import { useDispatch } from "react-redux";
import { setUserPage } from "../../store/features/userManagementSlice";

export default function UserResults() {
  const { users, count, page, params } = useSelector((state) => state.userManagement);
  const dispatch = useDispatch();

  const linkToCase = (params) => {
    return <Link to={"/user/" + params.id}>{params.full_name}</Link>;
  };

  const columns = [
    { field: "full_name", headerName: "Name", renderCell: linkToCase, width: "15%", sortable: false },
    { field: "username", headerName: "Email", width: 270, width: "20%", sortable: false },
    { field: "status", headerName: "Status", width: 270, width: "20%", sortable: false },
  ];

  const updatePage = (page) => {
    getUsers(params, page);
    dispatch(setUserPage(page));
  };

  useEffect(() => {
    getUsers(params);
  }, []);

  return (
    <>
      <TableContainer>
        <Table size="medium" stickyHeader>
          <colgroup>
            {columns.map((column, index) => (
              <col key={index} width={column.width} />
            ))}
          </colgroup>
          <TableHead className="tableHeader">
            <TableRow>
              {columns.map((column, index) => (
                <TableCell key={index} align="left">
                  {column.headerName}
                </TableCell>
              ))}
            </TableRow>
          </TableHead>
          <TableBody>
            {users?.map((user, index) => {
              return (
                <TableRow key={index} style={{ height: 10 }}>
                  {columns.map((column, index) => {
                    return column?.renderCell ? (
                      <TableCell align="left" key={index}>
                        {column.renderCell(user)}
                      </TableCell>
                    ) : (
                      <TableCell align="left" key={index}>
                        {user[column.field]}
                      </TableCell>
                    );
                  })}
                </TableRow>
              );
            })}
          </TableBody>
          <TableFooter>
            <TableRow>
              <TablePagination
                count={count}
                rowsPerPage={25}
                rowsPerPageOptions={[]}
                page={page}
                onPageChange={(e, newPage) => updatePage(newPage)}
              />
            </TableRow>
          </TableFooter>
        </Table>
      </TableContainer>
    </>
  );
}
