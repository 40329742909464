import React from "react";
import { BrowserRouter } from "react-router-dom";
import { ThemeProvider, StyledEngineProvider } from "@mui/material/styles";
import { Provider } from "react-redux";
import { createRoot } from "react-dom/client";

import store from "./store/store.js";
import "./index.scss";
import { theme } from "./lib/materialTheme.js";
import { App } from "./components/App.jsx";

document.addEventListener("DOMContentLoaded", () => {
  const root = createRoot(document.body.appendChild(document.createElement("div")));
  const relativeUri = process.env.RELATIVE_URI;
  root.render(
    <StyledEngineProvider injectFirst>
      <ThemeProvider theme={theme}>
        <BrowserRouter basename={relativeUri}>
          <Provider store={store}>
            <App />
          </Provider>
        </BrowserRouter>
      </ThemeProvider>
    </StyledEngineProvider>
  );
});
