import React from "react";

import Grid from "@mui/material/Grid";
import TextField from "@mui/material/TextField";

export const ClinicianPhone = (props) => {
  return (
    <>
      {props.readonly ? (
        <Grid container spacing={2}>
          <Grid item xs={12}>
            <TextField
              name="clinician.phone"
              fullWidth={true}
              inputProps={{ readOnly: props.readonly }}
              InputLabelProps={{ shrink: true }}
              variant="standard"
              label="Phone Number"
              data-testid="clinician.phone"
              {...props.register("clinician.phone")}
            />
          </Grid>
        </Grid>
      ) : (
        <Grid container spacing={2}>
          <Grid item xs={4}>
            <TextField
              name="clinician.area_code"
              inputProps={{ readOnly: props.readonly }}
              label="Area Code *"
              variant="standard"
              error={!!props.errors.clinician?.area_code}
              helperText={
                props.errors.clinician?.area_code &&
                props.errors.clinician?.area_code.message
              }
              {...props.register("clinician.area_code")}
              InputLabelProps={{
                shrink: props.watch("clinician.area_code") !== "",
              }}
            />
          </Grid>
          <Grid item xs={8}>
            <TextField
              name="clinician.phone_number"
              inputProps={{ readOnly: props.readonly }}
              label="Phone Number *"
              variant="standard"
              error={!!props.errors.clinician?.phone_number}
              helperText={
                props.errors.clinician?.phone_number &&
                props.errors.clinician?.phone_number.message
              }
              {...props.register("clinician.phone_number")}
              InputLabelProps={{
                shrink: props.watch("clinician.phone_number") !== "",
              }}
            />
          </Grid>
        </Grid>
      )}
    </>
  );
};
