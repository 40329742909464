import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  currentUser: {},
  loggedIn: false,
  initialized: false,
  showRegistrationSuccess: false,
};

const sessionSlice = createSlice({
  name: "session",
  initialState,
  reducers: {
    setUser(state, action) {
      state.currentUser = action.payload;
      state.loggedIn = true;
    },
    logout(state) {
      state.currentUser = {};
      state.loggedIn = false;
    },
    setInitialized(state, action) {
      state.initialized = action.payload;
    },
    setRegistrationSuccess(state, action) {
      state.showRegistrationSuccess = action.payload;
    },
  },
});

export const { setUser, logout, setInitialized, setRegistrationSuccess } = sessionSlice.actions;

export default sessionSlice.reducer;
