import React, { useEffect } from "react";
import { Table, TableBody, TableCell, TableContainer, TableFooter, TableHead, TablePagination, TableRow } from "@mui/material";
import { useDispatch, useSelector } from "react-redux";
import { Link } from "react-router-dom";
import { pathname } from "../../lib/misc";
import { getFacilities } from "../../helpers/facilityManagementHelper";
import { setFacilityPage } from "../../store/features/facilityManagementSlice";

export default function FacilityResults() {
  const facilities = useSelector((state) => state.facilityManagement);
  const dispatch = useDispatch();

  console.log(facilities);

  const linkToCase = (params) => {
    return <Link to={"/facility/" + params.id}>{params.name}</Link>;
  };

  const facilityStatus = (params) => {
    if (params.active) {
      return "ACTIVE";
    } else {
      return "INACTIVE";
    }
  };

  const bulkUploadStatus = (params) => {
    if (params.active) {
      return "ACTIVE";
    } else {
      return "INACTIVE";
    }
  };

  const columns = [
    { field: "name", headerName: "Name", renderCell: linkToCase, width: "15%", sortable: false },
    { field: "active", headerName: "Facility Status", renderCell: facilityStatus, width: 270, width: "20%", sortable: false },
    { field: "bulk_upload", headerName: "Bulk Upload Status", renderCell: bulkUploadStatus, width: 270, width: "20%", sortable: false },
  ];

  const updatePage = (page) => {
    getFacilities(facilities.params, page);
    dispatch(setFacilityPage(page));
  };

  useEffect(() => {
    getFacilities(facilities.params);
  }, []);

  return (
    <>
      <TableContainer>
        <Table size="medium" stickyHeader>
          <colgroup>
            {columns.map((column, index) => (
              <col key={index} width={column.width} />
            ))}
          </colgroup>
          <TableHead className="tableHeader">
            <TableRow>
              {columns.map((column, index) => (
                <TableCell key={index} align="left">
                  {column.headerName}
                </TableCell>
              ))}
            </TableRow>
          </TableHead>
          <TableBody>
            {facilities?.facilities.map((facility, index) => {
              return (
                <TableRow key={index}>
                  {columns.map((column, index) => {
                    return column?.renderCell ? (
                      <TableCell align="left" key={index}>
                        {column.renderCell(facility)}
                      </TableCell>
                    ) : (
                      <TableCell align="left" key={index}>
                        {facility[column.field]}
                      </TableCell>
                    );
                  })}
                </TableRow>
              );
            })}
          </TableBody>
          <TableFooter>
            <TableRow>
              <TablePagination
                count={facilities.count}
                rowsPerPage={25}
                rowsPerPageOptions={[]}
                page={facilities.page}
                onPageChange={(e, newPage) => updatePage(newPage)}
              />
            </TableRow>
          </TableFooter>
        </Table>
      </TableContainer>
    </>
  );
}
