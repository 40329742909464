import { createTheme } from "@mui/material/styles";
import { blue } from "@mui/material/colors";

import config from "../../../config.json";

export const theme = createTheme({
  palette: {
    primary: {
      main: config?.theme?.primary,
    },
    secondary: {
      main: config?.theme?.secondary,
    },
    info: {
      main: blue[500],
    },
  },
  components: {
    MuiButton: {
      styleOverrides: {
        containedPrimary: {
          color: "white",
        },
        containedSecondary: {
          color: "white",
        },
      },
    },
  },
});
