import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  limit: 25,
  count: 0,
  page: 0,
  params: {
    facilities: [],
    active: true,
    bulk_upload: true,
  },
  facilities: [],
};

const facilityManagementSlice = createSlice({
  name: "facilityManagement",
  initialState,
  reducers: {
    setFacilityPage(state, action) {
      state.page = action.payload;
    },
    setFacilityCount(state, action) {
      state.count = action.payload;
    },
    setFacilitySearchParams(state, action) {
      state.params = action.payload;
    },
    setFacilities(state, action) {
      state.facilities = action.payload;
    },
  },
});

export const {
  setFacilityPage,
  setFacilityCount,
  setFacilitySearchParams,
  setFacilities,
} = facilityManagementSlice.actions;

export default facilityManagementSlice.reducer;
