export const PRIVILEGES = {
    MANAGE_USERS: 'manage_users',
    MANAGE_FACILITIES: 'manage_facilities',
    MANAGE_ROLES: 'manage_roles',
    MANAGE_CONDITIONS: 'manage_conditions',
    MANAGE_FACILITY_USERS: 'manage_facility_users',
    VIEW_USER_FACILITIES: 'view_user_facilities',
    MANAGE_NOTIFICATIONS: 'manage_notifications',
    MANAGE_FACILITY_ADDRESS: 'manage_facility_address',
    MANAGE_FACILITY: 'manage_facility'
}