import { createSlice } from "@reduxjs/toolkit";
import moment from "moment";

const initialState = {
  limit: 25,
  count: 0,
  page: 0,
  params: {
    fromDate: moment().subtract(1, "months").format("YYYY-MM-DD"),
    toDate: moment().format("YYYY-MM-DD"),
    selectedFacilities: [],
  },
  uploads: [],
};

const bulkUploadsSlice = createSlice({
  name: "bulkUploads",
  initialState,
  reducers: {
    setBulkUploadPage(state, action) {
      state.page = action.payload;
    },
    setBulkUploadCount(state, action) {
      state.count = action.payload;
    },
    setBulkUploadSearchParams(state, action) {
      state.params = action.payload;
    },
    setBulkUploads(state, action) {
      state.uploads = action.payload;
    },
  },
});

export const {
  setBulkUploadPage,
  setBulkUploadCount,
  setBulkUploadSearchParams,
  setBulkUploads,
} = bulkUploadsSlice.actions;

export default bulkUploadsSlice.reducer;
