import React, { useEffect, useState } from "react";
import { useForm } from "react-hook-form";
import Button from "@mui/material/Button";
import CardContent from "@mui/material/CardContent";
import Card from "@mui/material/Card";
import FormControl from "@mui/material/FormControl";
import Grid from "@mui/material/Grid";
import TextField from "@mui/material/TextField";
import { useDispatch, useSelector } from "react-redux";

import axios from "../../lib/axios";
import RHFMultiSelect from "../RHFMultiSelect";
import { constructCaseSearchParams } from "../../helpers/notificationHelper";
import { checkPrivilege } from "../../helpers/privilegesHelper";
import { PRIVILEGES } from "../../constants/roles.constants";
import { useCaseSearchOptions } from "../../hooks/useCaseSearchOptions";
import {
  setCases,
  setCount,
  setPage,
  setSearchParams,
} from "../../store/features/caseSlice";

export default function CaseSearch(props) {
  const { currentUser } = useSelector((state) => state.session);
  const dispatch = useDispatch();
  const [filteredCounties, setFilteredCounties] = useState([]);
  const { register, handleSubmit, control, reset } = useForm();
  const { counties, conditions, facilities } = useCaseSearchOptions();

  const search = (e) => {
    // console.log(e)
    dispatch(setSearchParams(e));
    const params = constructCaseSearchParams(e);
    axios.get("/notifications/search.json", { params }).then((resp) => {
      dispatch(setCases(resp.data.notifications));
      dispatch(setCount(resp.data.total_entries));
      dispatch(setPage(0));
    });
  };

  // console.log(currentUser)
  const now = new Date();
  const fromDate = new Date(new Date().setMonth(now.getMonth() - 1))
    .toISOString()
    .substr(0, 10);
  const toDate = new Date().toISOString().substr(0, 10);

  const defaultValue = {
    lastName: "",
    fromDate: fromDate,
    toDate: toDate,
    selectedConditions: [],
    selectedCounties: [],
    selectedFacilities: [],
  };

  useEffect(() => {
    setFilteredCounties(
      counties?.filter((county) => county.code === process.env.DEFAULT_STATE)
    );
  }, [counties]);

  useEffect(() => {
    search({ fromDate, toDate });
  }, []);

  return (
    <div className="m-3">
      <Card variant="outlined">
        <form onSubmit={handleSubmit(search)}>
          <FormControl fullWidth>
            <CardContent>
              <p className="card-header m-0">Search Criteria</p>
              <Grid
                className="py-3"
                container
                justifyContent="space-between"
                alignContent="center"
              >
                <Grid item xs={5}>
                  <TextField
                    className="w-100"
                    variant="standard"
                    label="Enter Last Name"
                    name="lastName"
                    {...register("lastName")}
                  />
                </Grid>
                <Grid item xs={2}>
                  <TextField
                    name="fromDate"
                    type="date"
                    InputLabelProps={{ shrink: true }}
                    label="Search from date"
                    {...register("fromDate")}
                    variant="standard"
                    defaultValue={fromDate}
                  />
                </Grid>
                <Grid item xs={2}>
                  <TextField
                    name="toDate"
                    type="date"
                    label="Search to date"
                    variant="standard"
                    InputLabelProps={{ shrink: true }}
                    {...register("toDate")}
                    defaultValue={toDate}
                  />
                </Grid>
                <Grid container item xs={2} justifyContent="space-between">
                  <Grid item>
                    <Button
                      variant="contained"
                      color="primary"
                      onClick={() => reset(defaultValue)}
                    >
                      Clear
                    </Button>
                  </Grid>
                  <Grid item>
                    <Button variant="contained" color="secondary" type="submit">
                      Search
                    </Button>
                  </Grid>
                </Grid>
              </Grid>

              {checkPrivilege(currentUser, PRIVILEGES.MANAGE_FACILITIES) && (
                <Grid container className="pt-3" spacing={1}>
                  <Grid item xs={4}>
                    <RHFMultiSelect
                      fullWidth
                      options={conditions}
                      name="selectedConditions"
                      label="Choose conditions to filter"
                      placeholder="Conditions"
                      control={control}
                      selectAll={true}
                      multiple={true}
                      autocompleteProps={{
                        limitTags: 2,
                        disableCloseOnSelect: true,
                      }}
                    />
                  </Grid>

                  <Grid item xs={4}>
                    <RHFMultiSelect
                      options={facilities}
                      fullWidth
                      name="selectedFacilities"
                      label="Choose facilities to filter"
                      placeholder="Facilities"
                      control={control}
                      selectAll={true}
                      multiple={true}
                      autocompleteProps={{
                        limitTags: 2,
                        disableCloseOnSelect: true,
                      }}
                    />
                  </Grid>

                  <Grid item xs={4}>
                    <RHFMultiSelect
                      options={filteredCounties}
                      fullWidth
                      name="selectedCounties"
                      label="Choose counties to filter"
                      placeholder="Counties"
                      control={control}
                      selectAll={true}
                      multiple={true}
                      autocompleteProps={{
                        readOnly: props.readonly,
                        limitTags: 2,
                        disableCloseOnSelect: true,
                      }}
                    />
                  </Grid>
                </Grid>
              )}
            </CardContent>
          </FormControl>
        </form>
      </Card>
    </div>
  );
}
