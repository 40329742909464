import { Button, Grid, Menu, MenuItem } from "@mui/material";
import { makeStyles } from "@mui/styles";
import React, { Fragment, useState } from "react";
import { adminMenu, appMenu } from "./utils/appMenu";
import { useLocation, useNavigate } from "react-router-dom";
import { checkPrivilege } from "../helpers/privilegesHelper";
import { PRIVILEGES } from "../constants/roles.constants";
import { useSelector } from "react-redux";
import LogOut from "./LogOut";
const useStyles = makeStyles({
  menubar: {
    background: "#fafafa",
    padding: "1rem",
  },
});
export const AppMenu = () => {
  const classes = useStyles();
  const location = useLocation();
  const navigate = useNavigate();
  const { currentUser, loggedIn } = useSelector((state) => state.session);
  // Submenu for Admin
  const [anchorEl, setAnchorEl] = useState(null);
  const openMenu = Boolean(anchorEl);

  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };
  return (
    <>
      {loggedIn && (
        <div className={classes.menubar} data-testid="menubar">
          <Grid container justifyContent="space-between">
            <Grid container item spacing={2} xs={9}>
              {appMenu.map((menu) => (
                <Fragment key={menu.id}>
                  <Grid item>
                    <Button variant="contained" color={location.pathname === menu.path ? "secondary" : "primary"} onClick={() => navigate(menu.path)}>
                      {menu.name}
                    </Button>
                  </Grid>
                </Fragment>
              ))}
              <Grid item>
                {!!(checkPrivilege(currentUser, PRIVILEGES.MANAGE_FACILITY) || checkPrivilege(currentUser, PRIVILEGES.MANAGE_FACILITIES)) && (
                  <Button
                    id="admin-button"
                    color="primary"
                    variant="contained"
                    aria-controls={openMenu ? "basic-menu" : undefined}
                    aria-haspopup="true"
                    aria-expanded={openMenu ? "true" : undefined}
                    onClick={handleClick}
                  >
                    Admin
                  </Button>
                )}
              </Grid>
              <Menu
                id="basic-menu"
                anchorEl={anchorEl}
                open={openMenu}
                onClose={handleClose}
                color="primary"
                MenuListProps={{
                  "aria-labelledby": "basic-button",
                }}
              >
                {adminMenu(currentUser).map(
                  (menu) =>
                    menu.show && (
                      <MenuItem
                        key={menu.id}
                        onClick={() => {
                          navigate(menu.path);
                          handleClose();
                        }}
                      >
                        {menu.name}
                      </MenuItem>
                    )
                )}
              </Menu>
            </Grid>
            <Grid container item spacing={2} alignItems="center" justifyContent="flex-end" xs={3}>
              <Grid item>
                <div className="flex flex-column align-center">
                  <p className="font-bold m-0">
                    {currentUser?.first_name} {currentUser?.last_name}
                  </p>
                </div>
              </Grid>
              <Grid item>
                <LogOut />
              </Grid>
            </Grid>
          </Grid>
        </div>
      )}
    </>
  );
};
