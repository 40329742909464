import { createSlice } from "@reduxjs/toolkit";

const initialConfig = require("../../../../config.json");

const configSlice = createSlice({
  name: "config",
  initialState: { config: initialConfig },
  reducers: {
    setConfig(state, action) {
      state.config = action.payload;
    },
  },
});

export const { setConfig } = configSlice.actions;

export default configSlice.reducer;
