import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  selectedRaces: [],
};

const patientSlice = createSlice({
  name: "patient",
  initialState,
  reducers: {
    setSelectedRaces(state, action) {
      state.selectedRaces = action.payload;
    },
  },
});

export const { setSelectedRaces } = patientSlice.actions;

export default patientSlice.reducer;
