import React from "react";
import PropTypes from "prop-types";
import TextField from "@mui/material/TextField";
import FormControl from "@mui/material/FormControl";
import FormHelperText from "@mui/material/FormHelperText";
import Autocomplete from "@mui/material/Autocomplete";
import useMediaQuery from "@mui/material/useMediaQuery";
import ListSubheader from "@mui/material/ListSubheader";
import { useTheme } from "@mui/material/styles";
import makeStyles from "@mui/styles/makeStyles";
import { VariableSizeList } from "react-window";

// TODO: Ensure VirtualizedAutocomplete has error and helperText prop support

const LISTBOX_PADDING = 8; // px

function renderRow(props) {
  const { data, index, style } = props;
  return React.cloneElement(data[index], {
    style: {
      ...style,
      top: style.top + LISTBOX_PADDING,
    },
  });
}

const OuterElementContext = React.createContext({});

const OuterElementType = React.forwardRef((props, ref) => {
  const outerProps = React.useContext(OuterElementContext);
  return <div ref={ref} {...props} {...outerProps} />;
});
OuterElementType.displayName = "OuterElementType";

function useResetCache(data) {
  const ref = React.useRef(null);
  React.useEffect(() => {
    if (ref.current != null) {
      ref.current.resetAfterIndex(0, true);
    }
  }, [data]);
  return ref;
}

// Adapter for react-window
const ListboxComponent = React.forwardRef(function ListboxComponent(props, ref) {
  const { children, ...other } = props;
  const itemData = React.Children.toArray(children);
  const theme = useTheme();
  const smUp = useMediaQuery(theme.breakpoints.up("sm"), { noSsr: true });
  const itemCount = itemData.length;
  const itemSize = smUp ? 36 : 48;

  const getChildSize = (child) => {
    if (React.isValidElement(child) && child.type === ListSubheader) {
      return 48;
    }

    return itemSize;
  };

  const getHeight = () => {
    if (itemCount > 8) {
      return 8 * itemSize;
    }
    return itemData.map(getChildSize).reduce((a, b) => a + b, 0);
  };

  const gridRef = useResetCache(itemCount);

  return (
    <div ref={ref}>
      <OuterElementContext.Provider value={other}>
        <VariableSizeList
          itemData={itemData}
          height={getHeight() + 2 * LISTBOX_PADDING}
          width="100%"
          ref={gridRef}
          outerElementType={OuterElementType}
          innerElementType="ul"
          itemSize={(index) => getChildSize(itemData[index])}
          overscanCount={5}
          itemCount={itemCount}
        >
          {renderRow}
        </VariableSizeList>
      </OuterElementContext.Provider>
    </div>
  );
});

ListboxComponent.propTypes = {
  children: PropTypes.node,
};

const useStyles = makeStyles({
  listbox: {
    boxSizing: "border-box",
    "& ul": {
      padding: 0,
      margin: 0,
    },
  },
});

export default function VirtualizedAutocomplete({
  defaultValue,
  options,
  error,
  control,
  fullWidth,
  onTextChange,
  label,
  readOnly,
  freeSolo,
  renderOption,
  getOptionSelected,
  getOptionLabel,
  disabled,
  changeme,
  onClose,
  value,
  ...otherProps
}) {
  const classes = useStyles();
  return (
    <FormControl error={error} fullWidth={fullWidth}>
      <Autocomplete
        // style={{ width: 300 }}
        disableListWrap
        disabled={disabled}
        freeSolo={freeSolo}
        classes={classes}
        ListboxComponent={ListboxComponent}
        options={options}
        readOnly={readOnly}
        onClose={onClose}
        isOptionEqualToValue={getOptionSelected}
        onChange={changeme}
        renderInput={(params) => (
          <TextField
            variant="standard"
            onChange={onTextChange}
            {...params}
            label={label}
            {...otherProps.register(otherProps.fieldName, otherProps.registerOpts)}
          />
        )}
        renderOption={renderOption}
        getOptionLabel={getOptionLabel}
      />
      {otherProps.helperText && <FormHelperText>{otherProps.helperText}</FormHelperText>}
    </FormControl>
  );
}
VirtualizedAutocomplete.propTypes = {
  defaultValue: PropTypes.string,
  value: PropTypes.string,
  options: PropTypes.array,
  label: PropTypes.string,
  freeSolo: PropTypes.bool,
  error: PropTypes.bool,
  fullWidth: PropTypes.bool,
  readOnly: PropTypes.bool,
  changeme: PropTypes.func,
  disabled: PropTypes.bool,
  onTextChange: PropTypes.func,
  getOptionLabel: PropTypes.func,
  onClose: PropTypes.func
};
