import { createSlice } from "@reduxjs/toolkit";

const initialConfig = {
  gender: [],
  sex: [],
  ethnicities: [],
  races: [],
  telephone_types: [],
  states: [],
  counties: [],
  specimen_sources: [],
  conditions: [],
  lab_types: [],
  facility_visit_types: [],
  facilities: [],
  labs: [],
  roles: [],
  test_types: [],
  treatments: [],
  condition_treatments: [],
  test_results: [],
  condition_test_types: [],
  condition_specimen_sources: [],
  no_lab: {
    lab: {},
    facility: {},
  },
  sexual_orientations: [],
};

const caseDropdownSlice = createSlice({
  name: "caseDropdown",
  initialState: { caseDropdown: initialConfig },
  reducers: {
    setCaseDropdownData(state, action) {
      state.caseDropdown = action.payload;
    },
  },
});

export const { setCaseDropdownData } = caseDropdownSlice.actions;

export default caseDropdownSlice.reducer;
