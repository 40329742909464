import React from "react";
import { useSelector } from "react-redux";
import config from "../../../config.json";

export default function Instructions() {
  const relativeUri = process.env.RELATIVE_URI;
  return (
    <div className="text-center">
      {config?.instructions?.accessibilityInfo?.enabled && (
        <p>
          {config?.instructions?.accessibilityInfo?.prefix}{" "}
          <a rel="noreferrer" href={config?.instructions?.accessibilityInfo?.link} target="_blank">
            {config?.instructions?.accessibilityInfo?.linkText}
          </a>
        </p>
      )}
      <b>
        {config?.instructions?.prefix} -{" "}
        <a rel="noreferrer" href={config?.instructions?.link} target="_blank">
          {config?.instructions?.linkText}
        </a>
      </b>
    </div>
  );
}
