import { Button } from "@mui/material";
import React from "react";
import { Link } from "react-router-dom";
import { pathname } from "../../lib/misc";
import { API_URL } from "../utils/url";

export const SignIn = ({ setOpen }) => {
  const relativePath = process.env.RELATIVE_URI;
  return (
    <div className="flex flex-column">
      <Button
        data-testid="signin"
        variant="contained"
        color="primary"
        style={{
          height: "36px",
          padding: "0 16px",
          letterSpacing: ".0892857143em",
        }}
        onClick={() => {
          window.location.href = relativePath + API_URL.USER_SIGNIN;
        }}
      >
        Sign In
      </Button>
      <Link
        to={"/forgot-password"}
        onClick={() => {
          setOpen(false);
        }}
        style={{ fontSize: "12px", padding: "5px" }}
      >
        Forgot Password
      </Link>
    </div>
  );
};
