import React, { useEffect } from "react";
import Button from "@mui/material/Button";
import CardContent from "@mui/material/CardContent";
import Card from "@mui/material/Card";
import FormControl from "@mui/material/FormControl";
import Grid from "@mui/material/Grid";
import { getFacilities } from "../../helpers/facilityManagementHelper";
import { Controller, useForm } from "react-hook-form";
import { FormControlLabel, Switch } from "@mui/material";
import RHFMultiSelect from "../RHFMultiSelect";
import { useDispatch, useSelector } from "react-redux";
import { checkPrivilege } from "../../helpers/privilegesHelper";
import { PRIVILEGES } from "../../constants/roles.constants";
import { setFacilitySearchParams } from "../../store/features/facilityManagementSlice";

export default function FacilitySearch() {
  const { register, handleSubmit, control, reset, setValue, getValues, formState } = useForm({
    defaultValues: {
      facilities: [],
      active: true,
      bulk_upload: true,
    },
  });
  const { facilities } = useSelector((state) => state.caseDropdown.caseDropdown);
  const { currentUser } = useSelector((state) => state.session);
  const dispatch = useDispatch();

  const search = (e) => {
    const _facilities = [];
    e?.facilities.forEach((facility) => {
      _facilities.push(facility.id);
    });
    e.facilities = _facilities;
    console.log(e);
    dispatch(setFacilitySearchParams(e));
    getFacilities(e);
  };

  const resetValues = () => {
    reset(defaultValue);
    dispatch(setFacilitySearchParams(defaultValue));
    getFacilities(defaultValue);
  };

  const defaultValue = {
    facilities: [],
    active: true,
    bulk_upload: true,
  };

  useEffect(() => {
    return () => {
      reset(defaultValue);
      dispatch(setFacilitySearchParams(defaultValue));
    };
  }, []);

  return (
    <Card variant="outlined">
      <form onSubmit={handleSubmit(search)}>
        <FormControl fullWidth>
          <CardContent>
            <p className="card-header m-0">Search Criteria</p>
            <Grid className="py-3" container justifyContent="space-between" alignContent="center">
              <Grid item xs={3}>
                <RHFMultiSelect
                  options={checkPrivilege(currentUser, PRIVILEGES.MANAGE_FACILITIES) ? facilities : currentUser?.facilities}
                  fullWidth
                  name="facilities"
                  label="Choose facilities to filter"
                  placeholder="Facilities"
                  control={control}
                  selectAll={true}
                  multiple={true}
                  autocompleteProps={{ limitTags: 2, disableCloseOnSelect: true }}
                />
              </Grid>
              <Grid className="pt-3" item xs={3}>
                <FormControlLabel
                  control={
                    <Controller
                      control={control}
                      name="active"
                      render={({ field: { value, ...field } }) => (
                        <Switch
                          {...field}
                          checked={!!value}
                          inputProps={{
                            "aria-label": "controlled",
                          }}
                        />
                      )}
                    />
                  }
                  label="Active"
                  labelPlacement="start"
                />
              </Grid>
              <Grid className="pt-3" item xs={3}>
                <FormControlLabel
                  control={
                    <Controller
                      control={control}
                      name="bulk_upload"
                      render={({ field: { value, ...field } }) => (
                        <Switch
                          {...field}
                          checked={!!value}
                          inputProps={{
                            "aria-label": "controlled",
                          }}
                        />
                      )}
                    />
                  }
                  label="Bulk Upload"
                  labelPlacement="start"
                />
              </Grid>
              <Grid container item xs={2} justifyContent="space-between">
                <Grid item>
                  <Button variant="contained" color="primary" onClick={() => resetValues()}>
                    Clear
                  </Button>
                </Grid>
                <Grid item>
                  <Button variant="contained" color="secondary" type="submit">
                    Search
                  </Button>
                </Grid>
              </Grid>
            </Grid>
          </CardContent>
        </FormControl>
      </form>
    </Card>
  );
}
