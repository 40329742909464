import { Button, Card, CardContent } from "@mui/material";
import React, { useState, useEffect } from "react";
import * as yup from "yup";
import { yupResolver } from "@hookform/resolvers/yup";
import { useForm } from "react-hook-form";
import UserFacility from "./UserFacility";
import RHFMultiSelect from "../RHFMultiSelect";
import api from "../../lib/axios";
import { useDispatch, useSelector } from "react-redux";
import { openSnackbar } from "../../store/features/snackbarSlice";

export default function UserFacilities(props) {
  const [facilities, setFacilities] = useState([]);
  const [facility, setFacility] = useState("");
  const [disabled, setDisabled] = useState(true);
  const [role, setRole] = useState("");
  const { roles } = useSelector((state) => state.caseDropdown.caseDropdown);
  const dispatch = useDispatch();

  const formOpts = { mode: "all" };
  const {
    register,
    handleSubmit,
    getValues,
    reset,
    watch,
    control,
    formState: { errors },
  } = useForm();

  React.useEffect(() => {
    const values = watch();
    if (values?.facility_id?.id && values?.role_id?.id) {
      setDisabled(false);
    }
  }, [watch()]);

  const userFacilityObject = yup.object().shape({
    facility_id: yup
      .number()
      .transform((value, originalvalue) => {
        if (originalvalue !== null) {
          value = originalvalue.id;
          return value;
        }
      })
      .required("Required")
      .typeError("Please select a facility"),
    role_id: yup
      .number()
      .transform((value, originalvalue) => {
        if (originalvalue !== null) {
          value = originalvalue.id;
          return value;
        }
      })
      .required("Required")
      .typeError("Please select a role"),
  });

  formOpts.resolver = yupResolver(userFacilityObject);

  React.useEffect(() => {
    console.log("watch: ", watch());
  }, [watch]);

  useEffect(() => {
    api
      .get("/api/facilities/")
      .then((response) => {
        setFacilities(response.data.facilities);
      })
      .catch((e) => {
        console.log(e);
      });
  }, [setFacilities]);

  const addFacility = () => {
    const params = new URLSearchParams();
    params.append("facility_id", getValues().facility_id.id);
    params.append("role_id", getValues().role_id.id);
    params.append("user_id", props?.user?.id);
    api
      .post("/user_facilities", {}, { params })
      .then((res) => {
        props.getUser();
        dispatch(openSnackbar({ message: res?.data?.message, severity: "success" }));
        reset({
          facility_id: {},
          role_id: {},
        });
        setDisabled(true);
      })
      .catch((err) => {
        console.log(err);
        reset({
          facility_id: {},
          role_id: {},
        });
        setDisabled(true);
        dispatch(openSnackbar({ message: "Error adding Facility", severity: "error" }));
      });
  };

  const childProps = (facility) => {
    return {
      ...props,
      facility,
      facilities,
    };
  };

  return (
    <>
      <Card className="mt-3" variant="outlined">
        <CardContent>
          <p className="m-0 card-header">User Facilities</p>
          <div className="pt-3">
            {props?.user?.facilities?.map((facility, index) => {
              return (
                <div key={index}>
                  <UserFacility {...childProps(facility)} />
                </div>
              );
            })}
          </div>
          <div className="pt-3">
            <p className="m-0 card-header">Add new facility</p>
            <form>
              <div className="pt-3 flex">
                <div className="w-100">
                  <RHFMultiSelect
                    name="facility_id"
                    fullWidth
                    register={register}
                    label="Facility *"
                    control={control}
                    options={facilities}
                    objGOS={true}
                    error={!!errors.condition_id}
                    helperText={errors.condition_id && errors.condition_id?.message}
                  />
                </div>
                <div className="pl-3 w-100">
                  <RHFMultiSelect
                    name="role_id"
                    fullWidth
                    register={register}
                    label="Role *"
                    control={control}
                    options={roles}
                    error={!!errors.role_id}
                    helperText={errors.role_id && errors.role_id?.message}
                    objGOS={true}
                  />
                </div>
              </div>
              <Button className="mt-3 " color="primary" variant="contained" onClick={addFacility} disabled={disabled}>
                Add Facility
              </Button>
            </form>
          </div>
        </CardContent>
      </Card>
    </>
  );
}
