import { useEffect, useState } from "react";
import { useDispatch } from "react-redux";

import api from "../lib/axios";
import { API_URL } from "../components/utils/url";
import { setInitialized, setUser } from "../store/features/sessionSlice";
import { useNewCaseOptions } from "./useNewCaseOptions";

export const useInit = () => {
  const [loggedIn, setloggedIn] = useState(false);
  const optionsData = useNewCaseOptions();
  console.log("Initialized options data", +optionsData);
  const dispatch = useDispatch();
  useEffect(() => {
    initialize();
  }, []);

  const initialize = async () => {
    try {
      const currentUserRes = await api.get(API_URL.CURRENT_USER);
      dispatch(setInitialized(true));
      if (currentUserRes) {
        setloggedIn(true);
        dispatch(setUser(currentUserRes.data));
      } else {
        setloggedIn(false);
        dispatch(setUser({}));
      }
    } catch (err) {
      console.log(err);
      dispatch(setInitialized(true));
    }
  };

  return loggedIn;
};
