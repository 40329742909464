import React from "react";
import { Card, CardContent, Table, TableBody, TableCell, TableContainer, TableHead, TableRow } from '@mui/material'

export default function FacilityUsers(props) {
  const columns = [
    { field: "full_name", headerName: "Name", width: "15%", sortable: false },
    {
      field: "username",
      headerName: "Email",
      width: 270,
      width: "20%",
      sortable: false,
    },
    {
      field: "status",
      headerName: "Status",
      width: 270,
      width: "20%",
      sortable: false,
    },
  ];

  return (
    <>
    <Card className="mt-3" variant="outlined">
          <CardContent>
          <p className="m-0 card-header">FACILITY USERS</p>
      <TableContainer>
        <Table size="medium" stickyHeader>
          <colgroup>
            {columns.map((column, index) => (
              <col key={index} width={column.width} />
            ))}
          </colgroup>
          <TableHead className="tableHeader">
            <TableRow>
              {columns.map((column, index) => (
                <TableCell key={index} align="left">
                  {column.headerName}
                </TableCell>
              ))}
            </TableRow>
          </TableHead>
          <TableBody>
            {
            props?.users?.map((user, index) => {
              return (
                <TableRow key={index}>
                  {columns.map((column, index) => {
                    return (
                      <TableCell
                        align="left"
                        key={index}
                      >
                        {user[column.field]}
                      </TableCell>
                    );
                  })}
                </TableRow>
              );
            })}
          </TableBody>
        </Table>
      </TableContainer>
      </CardContent>
      </Card>
    </>
  );
}
