import { useEffect, useState } from "react";
import { useDispatch } from "react-redux";

import api from "../lib/axios";
import { API_URL } from "../components/utils/url";
import { setSearchOptions } from "../store/features/caseSlice";

export const useCaseSearchOptions = () => {
  const [searchData, setSearchData] = useState({});
  const dispatch = useDispatch();
  useEffect(() => {
    getData();
  }, []);

  const getData = async () => {
    const searchDataRes = await api.get(API_URL.GET_CASE_SEARCH_OPTIONS);
    if (searchDataRes) {
      setSearchData(searchDataRes.data);
      dispatch(setSearchOptions(searchDataRes.data));
    }
  };

  return searchData;
};
