import React, { useEffect, useState } from "react";
import { Register } from "./Register";
import { SignIn } from "./SignIn";
import RegisterUser from "../RegisterUser";
import { GoogleReCaptchaProvider } from "react-google-recaptcha-v3";
import { makeStyles } from "@mui/styles";
import { useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { RegistrationSuccess } from "./RegistrationSuccess";

const useStyles = makeStyles({
  topButtons: {
    display: "flex",
    justifyContent: "space-evenly",
    width: "100%",
    background: "#fafafa",
    padding: "12px",
  },
  bodyStyle: {
    width: "1140px",
    margin: "0px auto",
    display: "flex",
    flex: "1 1 auto",
    flexDirection: "column",
    minHeight: "100vh",
    position: "relative",
    overflow: "hidden",
  },
});

export const Auth = () => {
  const [open, setOpen] = useState(false);
  const classes = useStyles();
  const { loggedIn } = useSelector((state) => state.session);
  const navigate = useNavigate();

  useEffect(() => {
    if (loggedIn) navigate(-1);
  }, [loggedIn]);

  const sitekey = process.env.RECAPTCHA_SITE_KEY;
  return (
    <div className={classes.bodyStyle}>
      <div className={classes.topButtons}>
        <SignIn setOpen={setOpen} />
        <Register setOpen={setOpen} open={open} />
      </div>
      {open && (
        <GoogleReCaptchaProvider reCaptchaKey={sitekey}>
          <RegisterUser setOpen={setOpen} open={open} />
        </GoogleReCaptchaProvider>
      )}
      <RegistrationSuccess />
    </div>
  );
};
