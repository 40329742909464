import React, { useEffect } from "react";
import { useSelector } from "react-redux";
import Card from "@mui/material/Card";
import CardContent from "@mui/material/CardContent";
import CardHeader from "@mui/material/CardHeader";
import Grid from "@mui/material/Grid";
import TextField from "@mui/material/TextField";
import Typography from "@mui/material/Typography";

import { ClinicianPhone } from "./ClinicianPhone";
import RHFMultiSelect from "../RHFMultiSelect.jsx";

export const ClinicianInformation = (props) => {
  const { currentUser } = useSelector((state) => state.session);
  const { states, counties } = useSelector((state) => state.caseDropdown.caseDropdown);
  const handleFacilitySelection = (e, v) => {
    const selectedFacility = currentUser.facilities.find((facility) => facility === v);
    if (v === null || !selectedFacility) {
      resetFormValues();
      return;
    }
    const facilityProperties = {
      facility_id: selectedFacility,
      "facility.name": selectedFacility.name,
      "clinician.area_code": selectedFacility?.area_code,
      "clinician.phone_number": selectedFacility?.phone_number,
      "facility.address.street": selectedFacility?.full_address.street,
      "facility.address.unit": selectedFacility?.full_address.unit,
      "facility.address.city": selectedFacility?.full_address.city,
      "facility.address.zip": selectedFacility?.full_address.zip,
      "facility.address.state_id": states.find((state) => state.id === selectedFacility?.full_address.state_id),
      "facility.address.county_id": counties.find((county) => county.id === selectedFacility?.full_address.county_id),
    };

    Object.entries(facilityProperties).forEach(([key, value]) => {
      props.setValue(key, value);
    });
  };

  const resetFormValues = () => {
    const formValuesToReset = [
      "facility_id",
      "facility.name",
      "clinician.area_code",
      "clinician.phone_number",
      "facility.address.street",
      "facility.address.unit",
      "facility.address.city",
      "facility.address.zip",
      "facility.address.state_id",
      "facility.address.county_id",
    ];

    formValuesToReset.forEach((key) => {
      props.setValue(key, null);
    });
  };

  useEffect(() => {
    if (!props.caseId) {
      props.setValue("clinician.first_name", currentUser.first_name);
      props.setValue("clinician.last_name", currentUser.last_name);
      handleFacilitySelection(null, currentUser?.facilities[0]);
    }
  }, [props.initialized]);

  return (
    <Card variant="outlined">
      <CardHeader title={<p className="m-0 card-header">REPORTER INFORMATION</p>} />
      <CardContent>
        <Grid className="py-3" container spacing={2}>
          <Grid item xs={2}>
            <Typography variant="subtitle2">Facility</Typography>
            <div>
              <RHFMultiSelect
                name="facility_id"
                fullWidth
                register={props.register}
                readOnly={props.readonly}
                control={props.control}
                label="Facility *"
                options={currentUser.facilities}
                getOptionLabel={(o) => o?.name || ""}
                getOptionSelected={(o) => o?.name || ""}
                onChange={handleFacilitySelection}
                error={!!props.errors?.facility_id}
                helperText={props.errors?.facility_id && props.errors?.facility_id.message}
                objGOS={true}
                inputProps={{ "data-testid": "facility_id" }}
              />
            </div>
          </Grid>
          <Grid item xs={3}>
            <Typography variant="subtitle2">Name</Typography>
            <div>
              <TextField
                className="w-100"
                name="clinician.last_name"
                inputProps={{ readOnly: props.readonly }}
                label="Reporter Last Name *"
                InputLabelProps={{ shrink: props.watch("clinician.last_name") !== "" }}
                variant="standard"
                {...props.register("clinician.last_name")}
              />
            </div>
          </Grid>
          <Grid item xs={3}>
            <Typography variant="subtitle2">&nbsp;</Typography>
            <div>
              <TextField
                className="w-100"
                name="clinician.first_name"
                inputProps={{ readOnly: props.readonly }}
                InputLabelProps={{ shrink: props.watch("clinician.first_name") !== "" }}
                label="Reporter First Name *"
                variant="standard"
                {...props.register("clinician.first_name")}
              />
            </div>
          </Grid>
          <Grid item xs={4}>
            <Typography variant="subtitle2">Phone Number</Typography>
            <ClinicianPhone {...props} />
          </Grid>
          <Grid item xs={3}>
            <TextField
              disabled={!props.readonly}
              InputProps={{ readOnly: props.readonly }}
              InputLabelProps={{ shrink: props.watch("street") !== "" }}
              label="Street *"
              variant="standard"
              className="w-100"
              {...props.register("facility.address.street")}
              error={!!props.errors.street}
              helperText={props.errors.street && props.errors.street.message}
              inputProps={{ "data-testid": "facility.address.street" }}
            />
          </Grid>
          <Grid item xs={2}>
            <TextField
              disabled={!props.readonly}
              InputProps={{ readOnly: props.readonly }}
              label="Unit"
              variant="standard"
              className="w-100"
              {...props.register("facility.address.unit")}
              InputLabelProps={{ shrink: props.watch("unit") !== "" }}
              inputProps={{ "data-testid": "facility.address.unit" }}
            />
          </Grid>
          <Grid item xs={2}>
            <TextField
              disabled={!props.readonly}
              InputProps={{ readOnly: props.readonly }}
              label="City *"
              variant="standard"
              className="w-100"
              {...props.register("facility.address.city")}
              InputLabelProps={{ shrink: props.watch("city") !== "" }}
              error={!!props.errors.city}
              helperText={props.errors.city && props.errors.city.message}
              inputProps={{ "data-testid": "facility.address.city" }}
            />
          </Grid>
          <Grid item xs={1}>
            <RHFMultiSelect
              name="facility.address.state_id"
              fullWidth
              register={props.register}
              disabled={!props.readonly}
              readOnly={props.readonly}
              control={props.control}
              label="State *"
              options={states}
              getOptionLabel={(o) => o?.code || ""}
              getOptionSelected={(o) => o?.code || ""}
              error={!!props.errors.state_id}
              helperText={props.errors.state_id && props.errors.state_id.message}
              objGOS={true}
              inputProps={{ "data-testid": "facility.address.state_id" }}
            />
          </Grid>
          <Grid item xs={2}>
            <RHFMultiSelect
              name="facility.address.county_id"
              fullWidth
              disabled={!props.readonly}
              readOnly={props.readonly}
              register={props.register}
              label="County *"
              getOptionLabel={(o) => o?.name || ""}
              getOptionSelected={(o) => o?.name || ""}
              control={props.control}
              options={counties}
              variant="standard"
              error={!!props.errors.county_id}
              helperText={props.errors.county_id && props.errors.county_id.message}
              objGOS={true}
              inputProps={{ "data-testid": "facility.address.county_id" }}
            />
          </Grid>
          <Grid item xs={2}>
            <TextField
              label="Zip *"
              variant="standard"
              className="w-100"
              disabled={!props.readonly}
              InputProps={{ readOnly: props.readonly }}
              {...props.register("facility.address.zip")}
              InputLabelProps={{
                shrink: props.watch("facility.address.zip") !== "",
              }}
              error={!!props.errors.zip}
              helperText={props.errors.zip && props.errors.zip.message}
              inputProps={{ "data-testid": "facility.address.zip" }}
            />
          </Grid>
        </Grid>
      </CardContent>
    </Card>
  );
};
