import React, { useState, useEffect } from "react";
import LocalPharmacyIcon from "@mui/icons-material/LocalPharmacy";
import DeleteIcon from "@mui/icons-material/Delete";
import EditIcon from "@mui/icons-material/Edit";
import SaveIcon from "@mui/icons-material/Save";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import DialogTitle from "@mui/material/DialogTitle";
import { Button, Grid, IconButton, MenuItem, Select } from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";
import api from "../../lib/axios";
import { useDispatch, useSelector } from "react-redux";
import { openSnackbar } from "../../store/features/snackbarSlice";

export default function UserFacility(props) {
  const [facility, setFacility] = useState(false);
  const [readOnly, setReadOnly] = useState(true);
  const { roles } = useSelector((state) => state.caseDropdown.caseDropdown);
  const [dialogOpen, setDialogOpen] = useState(false);
  const [role, setRole] = useState(null);
  const dispatch = useDispatch();
  useEffect(() => {
    setRole(props?.facility?.role?.role_id);
  }, []);

  const handleClickOpen = (facility) => {
    setDialogOpen(true);
    setFacility(facility);
  };

  const handleClose = () => {
    setDialogOpen(false);
  };

  const editFacility = () => {
    setReadOnly(false);
  };

  const closeEditing = () => {
    setReadOnly(true);
    setRole(props?.facility?.role?.role_id);
  };

  const saveFacility = () => {
    const params = new URLSearchParams();
    params.append("user_id", props?.facility?.user_id);
    params.append("facility_id", props?.facility?.id);
    params.append("role_id", role);
    api
      .patch("/user_facilities", {}, { params })
      .then((res) => {
        setReadOnly(true);
        props.getUser();
        dispatch(openSnackbar({ message: "User Facility updated successfully", severity: "success" }));
      })
      .catch((err) => {
        setReadOnly(true);
        props.getUser();
        dispatch(openSnackbar({ message: "Something went wrong", severity: "error" }));
      });
  };

  const changeRole = (event) => {
    setRole(event.target.value);
  };

  const deleteFacility = () => {
    const params = new URLSearchParams();
    params.append("user_id", props?.facility?.user_id);
    params.append("facility_id", props?.facility?.facility_id);
    api
      .delete("/user_facilities", {
        params: params,
      })
      .then((res) => {
        setDialogOpen(false);
        props.getUser();
        dispatch(openSnackbar({ message: "Facility deleted for the User", severity: "success" }));
      })
      .catch((err) => {
        dispatch(openSnackbar({ message: "Error deleting", severity: "error" }));
      });
  };

  return (
    <>
      <div>
        <Grid container>
          <Grid item xs={4}>
            <div className="flex align-center">
              <LocalPharmacyIcon style={{ color: "#00000099" }} />
              <p className="pl-3">{props?.facility?.name}</p>
            </div>
          </Grid>
          <Grid item xs={6}>
            <Select className="ml-3" variant="standard" value={role} readOnly={readOnly} onChange={changeRole} label="Status *">
              {roles?.map((role, index) => {
                return (
                  <MenuItem key={index} value={role.id}>
                    {role.name}
                  </MenuItem>
                );
              })}
            </Select>
          </Grid>
          <Grid item xs={2}>
            <div className="facilityActions flex">
              {readOnly ? (
                <IconButton onClick={() => editFacility()}>
                  <EditIcon />
                </IconButton>
              ) : (
                <div className="flex">
                  <IconButton onClick={closeEditing}>
                    <CloseIcon />
                  </IconButton>
                  <IconButton onClick={saveFacility}>
                    <SaveIcon />
                  </IconButton>
                </div>
              )}
              {props?.user?.facilities?.length > 1 ? (
                <>
                  <IconButton className="ml-3" onClick={() => handleClickOpen(facility)} edge="end" aria-label="delete">
                    <DeleteIcon />
                  </IconButton>
                  <Dialog open={dialogOpen} onClose={handleClose} aria-labelledby="alert-dialog-title" aria-describedby="alert-dialog-description">
                    <DialogTitle id="alert-dialog-title">{"Delete User Facility"}</DialogTitle>
                    <DialogContent>
                      <DialogContentText id="alert-dialog-description">
                        Are you sure you want to delete the facility for this user ? This action cannot be undone.
                      </DialogContentText>
                    </DialogContent>
                    <DialogActions>
                      <Button onClick={handleClose}>No</Button>
                      <Button onClick={deleteFacility} autoFocus>
                        Yes
                      </Button>
                    </DialogActions>
                  </Dialog>
                </>
              ) : null}
            </div>
            <Dialog open={dialogOpen} onClose={handleClose} aria-labelledby="alert-dialog-title" aria-describedby="alert-dialog-description">
              <DialogTitle id="alert-dialog-title">{"Delete User Facility"}</DialogTitle>
              <DialogContent>
                <DialogContentText id="alert-dialog-description">
                  Are you sure you want to delete the facility for this user? This action cannot be undone.
                </DialogContentText>
              </DialogContent>
              <DialogActions>
                <Button onClick={handleClose}>No</Button>
                <Button onClick={deleteFacility} autoFocus>
                  Yes
                </Button>
              </DialogActions>
            </Dialog>
          </Grid>
        </Grid>
      </div>
    </>
  );
}
