import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  limit: 25,
  count: 0,
  page: 0,
  params: {
    lastName: "",
    firstName: "",
    email: "",
    status: [],
  },
  users: [],
};

const userManagementSlice = createSlice({
  name: "userManagement",
  initialState,
  reducers: {
    setUserPage(state, action) {
      state.page = action.payload;
    },
    setUserCount(state, action) {
      state.count = action.payload;
    },
    setUserSearchParams(state, action) {
      state.params = action.payload;
    },
    setUsers(state, action) {
      state.users = action.payload;
    },
  },
});

export const { setUserPage, setUserCount, setUserSearchParams, setUsers } = userManagementSlice.actions;

export default userManagementSlice.reducer;
