export const checkPrivilege = (user, _privilege) => {
    let present = false
    user?.facilities?.forEach((facility) => {
        facility?.privileges?.forEach((privilege) => {
            if(privilege === _privilege) {
                present = true
            }
        });
    });
    return present;
}

export const checkFacilityPrivilege = (user, facilityId, _privilege) => {
    let present = false
    let facility = user?.facilities?.find((facility) => 
        facility.facility_id === facilityId
    )
    facility?.privileges?.forEach((privilege) => {
        if(privilege === _privilege) {
            present = true
        }
    });
    return present;
}