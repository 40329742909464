import React from "react";
import CaseSearch from "./CaseSearch";
import { useSelector } from "react-redux";
import { CasesTable } from "./CasesTable";

export const Dashboard = () => {
  const { config } = useSelector((state) => state.config);
  return (
    <>
      <CaseSearch />
      <CasesTable />
    </>
  );
};
