import React, { Fragment, useEffect } from "react";
import { useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { APP_URL } from "../utils/url";

export const ProtectedRoute = (props) => {
  const { loggedIn } = useSelector((state) => state.session);
  const navigate = useNavigate();
  console.log("logged in", loggedIn);

  useEffect(() => {}, [loggedIn]);

  return <Fragment>{loggedIn ? props.children : null}</Fragment>;
};
