import React, { useEffect, useState } from "react";
import PropTypes from "prop-types";

import Grid from "@mui/material/Grid";
import { FormControl, FormHelperText, TextField } from "@mui/material";
import Input from "@mui/material/Input";
import InputLabel from "@mui/material/InputLabel";
import MenuItem from "@mui/material/MenuItem";
import ListItemText from "@mui/material/ListItemText";
import Select from "@mui/material/Select";
import Checkbox from "@mui/material/Checkbox";
import RHFMultiSelect from "./RHFMultiSelect";

import { useDispatch, useSelector } from "react-redux";
import { setSelectedRaces } from "../store/features/patientSlice";
import config from "../../../config.json";

const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;
const MenuProps = {
  PaperProps: {
    style: {
      maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
      width: 250,
    },
  },
};

export default function PatientSelects(props) {
  const { telephone_types, sex, gender, races, ethnicities, sexual_orientations } = useSelector((state) => state.caseDropdown.caseDropdown);
  const { selectedRaces } = useSelector((state) => state.patient);
  const patient = useSelector((state) => state.patientSearch);
  const dispatch = useDispatch();
  const readonly = patient.patientSelected || props.readonly;
  const sourcesLoaded = true;

  useEffect(() => {
    if (selectedRaces.length > 0) {
      setPatientRace(selectedRaces);
    }
  }, [selectedRaces]);

  const handleRaceChange = (event) => {
    const {
      target: { value },
    } = event;
    const raceValue = typeof value === "string" ? value.split(",") : value;
    dispatch(setSelectedRaces(raceValue));
  };

  const setPatientRace = (raceValue) => {
    const selectedRaceIds = races.filter((race) => raceValue.includes(race.name)).map(({ id }) => id);
    props.setValue("patient.race_ids", selectedRaceIds);
  };

  const patientPhone = readonly ? (
    <Grid container spacing={2}>
      <Grid item xs={12}>
        <TextField
          name="patient.phone"
          inputProps={{ readOnly: readonly }}
          InputLabelProps={{ shrink: props.watch("patient.phone") !== "" }}
          variant="standard"
          label="Phone Number"
          {...props.register("patient.phone")}
        />
      </Grid>
    </Grid>
  ) : (
    <Grid container spacing={2}>
      <Grid item xs={4}>
        <TextField
          name="patient.area_code"
          inputProps={{ readOnly: readonly }}
          InputLabelProps={{ shrink: props.watch("patient.area_code") !== "" }}
          label="Area Code"
          variant="standard"
          error={!!props.errors.patient?.area_code}
          helperText={props.errors.patient?.area_code && props.errors.patient?.area_code.message}
          {...props.register("patient.area_code")}
        />
      </Grid>
      <Grid item xs={8}>
        <TextField
          name="patient.phone_number"
          inputProps={{ readOnly: readonly }}
          InputLabelProps={{ shrink: props.watch("patient.phone_number") !== "" }}
          label="Phone Number"
          variant="standard"
          error={!!props.errors.patient?.phone_number}
          helperText={props.errors.patient?.phone_number && props.errors.patient?.phone_number.message}
          {...props.register("patient.phone_number")}
        />
      </Grid>
    </Grid>
  );

  return (
    <>
      <Grid className="pt-3" container spacing={2}>
        <Grid item xs={3}>
          {sourcesLoaded && (
            <RHFMultiSelect
              name="patient.telephone_type_id"
              fullWidth
              register={props.register}
              readOnly={readonly}
              objGOS={true}
              label="Phone Type"
              control={props.control}
              options={telephone_types}
            />
          )}
        </Grid>
        <Grid item xs={4}>
          {patientPhone}
        </Grid>
        <Grid item xs={5}>
          <TextField
            fullWidth
            name="patient.email"
            inputProps={{ readOnly: readonly }}
            InputLabelProps={{ shrink: props.watch("patient.email") !== "" }}
            label="Email"
            variant="standard"
            error={!!props.errors.patient?.email}
            helperText={props.errors.patient?.email && props.errors.patient?.email.message}
            {...props.register("patient.email")}
          />
        </Grid>
      </Grid>

      <Grid className="pt-3" container spacing={2}>
        <Grid item xs={3}>
          {sourcesLoaded && (
            <RHFMultiSelect
              name="patient.sex_id"
              fullWidth
              register={props.register}
              readOnly={readonly}
              label="Sex *"
              control={props.control}
              objGOS={true}
              options={sex}
              error={!!props.errors.patient?.sex_id}
              helperText={props.errors.patient?.sex_id && props.errors.patient?.sex_id.message}
              inputProps={{ "data-testid": "patient.sex_id" }}
            />
          )}
        </Grid>
        <Grid item xs={3}>
          {sourcesLoaded && (
            <RHFMultiSelect
              name="patient.gender_id"
              fullWidth
              register={props.register}
              readOnly={readonly}
              label="Gender"
              control={props.control}
              objGOS={true}
              options={gender}
              error={!!props.errors.patient?.gender_id}
              helperText={props.errors.patient?.gender_id && props.errors.patient?.gender_id.message}
              inputProps={{ "data-testid": "patient.gender_id" }}
            />
          )}
        </Grid>
        <Grid item xs={6}>
          {sourcesLoaded && (
            <RHFMultiSelect
              name="patient.ethnicity_id"
              fullWidth
              register={props.register}
              readOnly={readonly}
              label="Ethnicity"
              control={props.control}
              objGOS={true}
              options={ethnicities}
            />
          )}
        </Grid>
      </Grid>
      <Grid className="pt-3" container spacing={2}>
        <Grid item xs={6}>
          {sourcesLoaded && races.length > 0 && (
            <FormControl fullWidth>
              <InputLabel id="demo-multiple-checkbox-label">Race</InputLabel>
              <Select
                id="race-multiple-checkbox"
                multiple
                readOnly={readonly}
                value={selectedRaces}
                onChange={handleRaceChange}
                input={<Input label="Race" />}
                renderValue={(selected) => selected.join(", ")}
                MenuProps={MenuProps}
              >
                {races.map((race) => (
                  <MenuItem key={race.id} value={race.name}>
                    <Checkbox checked={selectedRaces.indexOf(race.name) > -1} />
                    <ListItemText primary={race.name} />
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
          )}
        </Grid>
        {config?.patient?.sexualOrientation && (
          <Grid item xs={6}>
            <RHFMultiSelect
              name="patient.sexual_orientation_id"
              fullWidth
              register={props.register}
              readOnly={readonly}
              label="Sexual Orientation"
              control={props.control}
              objGOS={true}
              options={sexual_orientations}
            />
          </Grid>
        )}
      </Grid>
    </>
  );
}
PatientSelects.propTypes = {
  control: PropTypes.object,
  readonly: PropTypes.bool,
  register: PropTypes.func,
  errors: PropTypes.shape({
    patient: PropTypes.shape({
      phone_number: PropTypes.object,
      area_code: PropTypes.object,
      gender_id: PropTypes.object,
    }),
  }),
};
