import { configureStore } from "@reduxjs/toolkit";

// Import your slices
import caseSlice from "./features/caseSlice";
import conditionSlice from "./features/conditionSlice";
import bulkUploadSlice from "./features/bulkUploadSlice";
import facilityManagementSlice from "./features/facilityManagementSlice";
import userManagementSlice from "./features/userManagementSlice";
import configSlice from "./features/configSlice";
import patientSearchSlice from "./features/patientSearchSlice";
import sessionSlice from "./features/sessionSlice";
import caseDropdownSlice from "./features/caseDropdownSlice";
import snackbarSlice from "./features/snackbarSlice";
import labSlice from "./features/labSlice";
import patientSlice from "./features/patientSlice";

// Combine all your slices into one root reducer
const rootReducer = {
  userManagement: userManagementSlice,
  facilityManagement: facilityManagementSlice,
  case: caseSlice,
  condition: conditionSlice,
  bulkUploads: bulkUploadSlice,
  config: configSlice,
  patientSearch: patientSearchSlice,
  session: sessionSlice,
  caseDropdown: caseDropdownSlice,
  snackbar: snackbarSlice,
  lab: labSlice,
  patient: patientSlice,
};

const store = configureStore({
  reducer: rootReducer,
  middleware: (getDefaultMiddleware) => getDefaultMiddleware(),
});

export default store;
