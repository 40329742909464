import React from 'react'
import FacilityResults from './FacilityResults'
import FacilitySearch from './FacilitySearch'

export default function FacilityManagement () {
  return (
      <>
        <p className='main-header m-0 text-center pb-3'>Facility Management</p>
        <FacilitySearch />
        <FacilityResults/>
      </>
  )
}
