import React, { useState, useEffect } from "react";
import Card from "@mui/material/Card";
import CardContent from "@mui/material/CardContent";
import Grid from "@mui/material/Grid";
import TextField from "@mui/material/TextField";
import { Button, FormControl, FormControlLabel, FormGroup, Switch } from "@mui/material";
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from "yup";
import { useNavigate, useParams } from "react-router-dom";
import { pathname, useMuiRegister } from "../../lib/misc";
import LoadingButton from "@mui/lab/LoadingButton";
import RHFMultiSelect from "../RHFMultiSelect";
import FacilityUsers from "./FacilityUsers";
import FacilityConditions from "./FacilityConditions";
import { checkPrivilege } from "../../helpers/privilegesHelper";
import { PRIVILEGES } from "../../constants/roles.constants";
import api from "../../config/axios.config";
import { useDispatch, useSelector } from "react-redux";
import { openSnackbar } from "../../store/features/snackbarSlice";

export default function ShowFacility() {
  //State Variables
  const [readOnly, setReadOnly] = useState(false);
  const [hasFacilitiesPrivilege, setFacilitiesPrivilege] = useState(true);
  const [facility, setFacility] = useState({});
  const [active, setActive] = useState(false);
  const [bulkUpload, setBulkUpload] = useState(false);
  const [dialogOpen, setDialogOpen] = useState(false);
  const [deleteLoading, setDeleteLoading] = useState(false);
  const [saveLoading, setSaveLoading] = useState(false);
  const [filteredCounties, setFilteredCounties] = useState([]);
  const [facilityError, setFacilityError] = useState(false);
  //React-Router Variables
  const params = useParams();
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const { currentUser } = useSelector((state) => state.session);
  const { states, counties } = useSelector((state) => state.caseDropdown.caseDropdown);

  // Yup Validation Schema
  const facilitySchema = yup.object().shape({
    name: yup.string().required("Required").default("").max(50, "Must be less than 50 characters"),
    active: yup.boolean().required().default(false),
    bulk_upload: yup.boolean().required().default(false),
    area_code: yup
      .string()
      .required("Required")
      .matches(/^\d\d\d$/, "Must be three digits"),
    phone_number: yup
      .string()
      .required("Required")
      .matches(/^\d{7}$/, "Must be seven digits"),
    address: yup.object().shape({
      street: yup
        .string()
        .required("Required")
        .matches(/^[0-9a-zA-Z ]*$/, "Only alphanumeric characters allowed"),
      city: yup.string().required("Required"),
      zip: yup
        .string()
        .required("Required")
        .matches(/^[0-9]{5}(-[0-9]{4})?$/, "Zip code must be ##### or #####-#### format"),
      unit: yup.string().nullable().default(""),
      county_id: yup
        .number()
        .transform((value, originalvalue) => {
          if (originalvalue !== null) {
            value = originalvalue.id;
            return value;
          }
        })
        .typeError("Please select a county")
        .required("Required"),
      state_id: yup
        .number()
        .transform((value, originalvalue) => {
          if (originalvalue !== null) {
            value = originalvalue.id;
            return value;
          }
        })
        .required("Required")
        .typeError("Please select a state"),
    }),
  });

  // Operations tied to the form validation
  const formOpts = { mode: "all" };
  formOpts.resolver = yupResolver(facilitySchema);
  const {
    handleSubmit,
    setValue,
    watch,
    control,
    reset,
    register,
    formState: { errors, isValid },
    getValues,
  } = useForm(formOpts);

  const getFacilities = () => {
    api
      .get(`/api/facilities/${params.id}`)
      .then((res) => {
        if (res.data.data) {
          setFacilityDefaults(res.data.data);
          setReadOnly(true);
        } else {
          navigate(pathname(true) + "facilities");
        }
      })
      .catch((err) => {
        setFacilityError(err?.response?.data?.error);
      });
  };

  useEffect(() => {
    if (states) {
      getFacilities();
    }
  }, [states]);

  useEffect(() => {
    const currentFacility = currentUser?.facilities?.find((facility) => facility.facility_id == params.id);
    if (currentFacility) {
      let present = false;
      currentFacility?.privileges?.forEach((privilege) => {
        if (privilege === PRIVILEGES.MANAGE_FACILITIES) {
          present = true;
        }
      });
      if (present) {
        setFacilitiesPrivilege(true);
      } else {
        setFacilitiesPrivilege(false);
      }
    }
  });

  const setFacilityDefaults = (facility) => {
    let defaultValues = {
      id: facility.id ? facility.id : "",
      name: facility.name ? facility.name : "",
      active: facility.active ? facility.active : false,
      bulk_upload: facility.bulk_upload ? facility.bulk_upload : false,
      address_id: facility.address_id ? facility.address_id : "",
      area_code: facility.area_code ? facility.area_code : "",
      phone_number: facility.phone_number ? facility.phone_number : "",
      address: {
        id: facility.address && facility.address.id ? facility.address.id : "",
        street: facility.address && facility.address.street ? facility.address.street : "",
        unit: facility.address && facility.address.unit ? facility.address.unit : "",
        city: facility.address && facility.address.city ? facility.address.city : "",
        zip: facility.address && facility.address.zip ? facility.address.zip : "",
        state_id: facility.address && facility.address.state_id ? states.find((s) => s.id === facility.address.state_id) : "",
        county_id: facility.address && facility.address.county_id ? counties.find((s) => s.id === facility.address.county_id) : "",
      },
    };
    setActive(facility.active);
    setBulkUpload(facility.bulk_upload);
    setFacility(facility);
    reset(defaultValues);
    handleStateChange(
      null,
      states.find((s) => s.id === facility.address.state_id)
    );
  };

  const editFacility = () => {
    setReadOnly(false);
  };

  const cancelEdit = () => {
    setFacilityDefaults(facility);
    setReadOnly(true);
  };

  const save = (e) => {
    const body = {
      facility: {
        ...e,
        id: params.id,
      },
    };
    setSaveLoading(true);
    api
      .patch(`/api/facilities/${params.id}`, body)
      .then((res) => {
        setFacilityDefaults(res.data.facility);
        dispatch(openSnackbar({ message: "Facility Updated", severity: "success" }));
        setSaveLoading(false);
        setReadOnly(true);
      })
      .catch((err) => {
        setFacilityDefaults(facility);
        dispatch(openSnackbar({ message: "Error: could not update Facility", severity: "error" }));
        setSaveLoading(false);
        setReadOnly(true);
      });
  };

  const handleActive = (event) => {
    setActive(event.target.checked);
    setValue("active", event.target.checked);
  };

  const handleBulkUpload = (event) => {
    setBulkUpload(event.target.checked);
    setValue("bulk_upload", event.target.checked);
  };

  const handleStateChange = (e, v) => {
    setValue("address.state_id", v);
    const c = counties.filter((county) => county.state_id === v?.id);
    setFilteredCounties(c);
  };

  const childProps = {
    readOnly,
    setValue,
    getValues,
    errors,
    register: useMuiRegister(register),
    control,
    watch,
    facility,
    getFacilities,
  };

  return (
    <>
      {facilityError ? (
        <Card className="mt-3" elevation={0} variant="outlined">
          <CardContent>
            <p className="font-bold text-center">{facilityError}</p>
          </CardContent>
        </Card>
      ) : (
        <>
          {" "}
          <div>
            <form onSubmit={handleSubmit(save)}>
              <Card className="mt-3" variant="outlined">
                <CardContent>
                  <FormGroup>
                    <FormControl>
                      <div className="flex justify-between">
                        <p className="m-0 card-header">FACILITY INFORMATION</p>
                        {readOnly && (
                          <Button color="secondary" variant="contained" onClick={editFacility}>
                            Edit Facility
                          </Button>
                        )}
                        {!readOnly && (
                          <div>
                            <Button color="secondary" variant="contained" onClick={cancelEdit}>
                              Cancel
                            </Button>
                            <LoadingButton className="ml-3" color="secondary" variant="contained" loading={saveLoading} type="submit">
                              Save Facility
                            </LoadingButton>
                          </div>
                        )}
                      </div>
                      <Grid className="pt-3" container spacing={2}>
                        <Grid item xs={4}>
                          <TextField
                            className="w-100"
                            name="name"
                            inputProps={{
                              readOnly: readOnly && !!hasFacilitiesPrivilege,
                            }}
                            label="Name *"
                            variant="standard"
                            disabled
                            InputLabelProps={{ shrink: watch("name") !== "" }}
                            {...register("name")}
                          />
                        </Grid>
                        <Grid className="pt-3" item xs={3}>
                          <FormControlLabel
                            value="start"
                            control={
                              <Switch
                                checked={active}
                                onChange={handleActive}
                                inputProps={{
                                  "aria-label": "controlled",
                                  disabled: readOnly || !hasFacilitiesPrivilege,
                                }}
                              />
                            }
                            label="Active"
                            labelPlacement="start"
                          />
                        </Grid>
                        <Grid className="pt-3" item xs={3}>
                          <FormControlLabel
                            value="start"
                            control={
                              <Switch
                                checked={bulkUpload}
                                onChange={handleBulkUpload}
                                inputProps={{
                                  "aria-label": "controlled",
                                  disabled: readOnly || !hasFacilitiesPrivilege,
                                }}
                              />
                            }
                            label="Bulk Upload"
                            labelPlacement="start"
                          />
                        </Grid>
                      </Grid>
                      <Grid className="pt-3" container spacing={2}>
                        <Grid item xs={4}>
                          <TextField
                            className="w-100"
                            name="area_code"
                            inputProps={{ readOnly: readOnly }}
                            label="Area Code *"
                            variant="standard"
                            InputLabelProps={{ shrink: watch("area_code") !== "" }}
                            error={!!errors.area_code}
                            helperText={errors.area_code && errors.area_code?.message}
                            {...register("area_code")}
                          />
                        </Grid>
                        <Grid item xs={4}>
                          <TextField
                            className="w-100"
                            name="phone_number"
                            inputProps={{ readOnly: readOnly }}
                            label="Phone Number *"
                            variant="standard"
                            InputLabelProps={{
                              shrink: watch("phone_number") !== "",
                            }}
                            error={!!errors.phone_number}
                            helperText={errors.phone_number && errors.phone_number?.message}
                            {...register("phone_number")}
                          />
                        </Grid>
                      </Grid>
                      <Grid className="pt-3" container spacing={2}>
                        <Grid item xs={3}>
                          <TextField
                            className="w-100"
                            variant="standard"
                            name="address.street"
                            inputProps={{ readOnly: readOnly }}
                            InputLabelProps={{
                              shrink: watch("address.street") !== "",
                            }}
                            error={!!errors.address?.street}
                            helperText={errors?.address?.street?.message}
                            label="Street *"
                            {...register("address.street")}
                          />
                        </Grid>
                        <Grid item xs={2}>
                          <TextField
                            className="w-100"
                            variant="standard"
                            name="address.unit"
                            inputProps={{ readOnly: readOnly }}
                            error={!!errors.address?.unit}
                            helperText={errors?.address?.unit?.message}
                            InputLabelProps={{
                              shrink: watch("address.unit") !== "",
                            }}
                            label="Unit"
                            {...register("address.unit")}
                          />
                        </Grid>
                        <Grid item xs={3}>
                          <TextField
                            className="w-100"
                            variant="standard"
                            name="address.city"
                            inputProps={{ readOnly: readOnly }}
                            InputLabelProps={{
                              shrink: watch("address.city") !== "",
                            }}
                            error={!!errors.address?.city}
                            helperText={errors?.address?.city?.message}
                            label="City *"
                            {...register("address.city")}
                          />
                        </Grid>
                        <Grid item xs={1}>
                          <RHFMultiSelect
                            name="address.state_id"
                            fullWidth
                            register={register}
                            readOnly={readOnly}
                            addBlankOption={true}
                            label="State *"
                            control={control}
                            options={states}
                            onChange={handleStateChange}
                            getOptionLabel={(o) => o.code || ""}
                            // getOptionSelected={o => o.code || ''}
                            error={!!errors.address?.state_id}
                            helperText={errors?.address?.state_id?.message}
                            objGOS={true}
                          />
                        </Grid>
                        <Grid item xs={1}>
                          <TextField
                            name="address.zip"
                            inputProps={{ readOnly: readOnly }}
                            variant="standard"
                            InputLabelProps={{
                              shrink: watch("address.zip") !== "",
                            }}
                            error={!!errors.address?.zip}
                            helperText={errors?.address?.zip?.message}
                            label="ZIP *"
                            {...register("address.zip")}
                          />
                        </Grid>
                        <Grid item xs={2}>
                          <RHFMultiSelect
                            name="address.county_id"
                            fullWidth
                            readOnly={readOnly}
                            register={register}
                            addBlankOption={true}
                            label="County *"
                            control={control}
                            getOptionLabel={(o) => o.name || ""}
                            getOptionSelected={(o) => o.name || ""}
                            options={filteredCounties}
                            variant="standard"
                            error={!!errors.address?.county_id}
                            helperText={errors?.address?.county_id?.message}
                            objGOS={true}
                          />
                        </Grid>
                      </Grid>
                    </FormControl>
                  </FormGroup>
                </CardContent>
              </Card>
            </form>
          </div>
          {checkPrivilege(currentUser, PRIVILEGES.MANAGE_FACILITIES) && <FacilityConditions {...childProps} />}
          {checkPrivilege(currentUser, PRIVILEGES.MANAGE_FACILITIES) && <FacilityUsers {...facility} />}
        </>
      )}
    </>
  );
}
